@import "./../../colorvariable.scss";
@import "../../mixin.scss";
@import "../../variable.scss";

.verify-success {
	outline: 1px solid red;
	height: 100vh;
	width: 100vw;
	overflow: hidden;

	&-left,
	&-right {
		padding: 4rem 10rem;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&-left {
		overflow: hidden;
		background-color: #f2f4f7;
		margin: auto 0;

		.onboarding {
			&-carousel {
				height: 55rem;
				width: 100%;
				position: relative;

				.swiper-pagination {
					margin-top: 3rem !important;
					width: 100px !important;
				}

				.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
					transform: scale(1);
				}

				.swiper-pagination-bullet-active-main {
					background: #c2c3c6;
					width: 4rem;
					border-radius: 50rem;
				}
			}

			&-slide {
				img {
					margin-bottom: 5rem;
					height: 30rem;
					width: 30rem;
					object-fit: contain;
				}

				h5 {
					@include display-sm;
				}

				p {
					margin-top: 1rem;
					@include text-lg;
				}
			}
		}
	}

	&-right {
		overflow: auto;
		display: flex;
		h2 {
			margin-bottom: 3rem;
			text-align: center;
			@include display-md;
		}

		.suc-form {
			width: 35vw;
			display: flex;
			flex-direction: column;
			gap: 2rem;
			margin-bottom: 1rem;

			#password {
				// padding-left: 1rem;
				padding-right: 4rem !important;
			}

			.J-inputComp-input {
				padding-inline: 1rem !important;
			}

			.isRequired::after {
				content: "*";
				color: $btn-error-hover;
			}

			.field-error {
				border-color: $btn-error-default;
			}
		}
	}
}

.verify-company-left {
	background: #193560;
	color: white;

	&__content-title {
		font-size: 4.8rem;
		font-style: normal;
		font-weight: 700;
		line-height: 140%; /* 67.2px */
		letter-spacing: -0.96px;

		@media only screen and (max-width: 768px) {
			font-size: 3rem;
		}
	}
	&__wrapper-step {
		display: flex;
		flex-direction: column;
		gap: 0.8rem;
		margin-top: 3.2rem;
		&__content-step {
			@media only screen and (max-width: 768px) {
				gap: 0.25rem;
				padding: 0.25rem;
			}
			display: flex;
			gap: 1.2rem;
			padding: 1rem 0;
			.company-checkbox-status {
				color: #a3aebf;
			}

			.active {
				color: #fff;
				font-weight: 600;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		max-height: 200px;
		padding: 1.25rem;
	}
}

.verify-company-right {
	@media only screen and (max-width: 768px) {
		justify-content: start;
		padding: 4rem 2rem;
	}
	&__title {
		font-size: 3rem;
		color: #1d2939;
		font-weight: 600;
		align-self: center;
		text-align: start !important;
		max-width: 550px;
		width: 100%;
	}

	&__button-group {
		display: flex;
		justify-content: space-between;
		max-width: 550px;
		width: 100%;
		margin-top: 5.2rem;
		align-self: center;
	}
}

.modal-error {
	color: var(--Black, var(--Black, #1d2939));
	.modal-error__alert-icon-circle {
		width: 78px;
		height: 78px;
	}

	&__modal-title {
		text-align: center;
		font-family: Noto Sans;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		margin-top: 42px;
	}

	&__modal-text {
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-top: 16px;
	}
}
