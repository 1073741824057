.tab-navigation {
    &__container {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #98A2B3;
    }
    &__wrapper {
        padding: 12px;
        padding-bottom: 0px;
        overflow-x: scroll;
        &::-webkit-scrollbar { 
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    &__sub-wrapper {
        display: flex;
        gap: 12px;
        color: var(--navy-blue-navy-blue-500, #667085);
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
    &__items {
        display: flex;
        gap: 8px;
        padding: 0 12px;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: -webkit-fill-available;
        &--active {
            color: #193560 !important;
        }
        & span {
            width: max-content;
        }
        &--count {
            background-color: #FC9607;
            color: #ffffff;
            padding: 1px 8px;
            padding: 0rem 0.8rem;
            border-radius: 2rem;
        }
    }
    &__border--active {
        width: 148px;
        height: 3px;
        background: #193560;
        margin-top: 0px !important;
        transition: 0.6s;
        border-radius: 8px;
    }
    &__employee-hired-count {
        display: flex;
        align-items: center;
        color: var(--gray-gray-500, #667085);
        font-size: 0.75em;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
}
