.detail-navigation {
    &__wrapper {
        padding-top: 16px !important;
        padding: 12px;
        padding-bottom: 0px;
        overflow-x: scroll;
        overflow-y: hidden;
        border-bottom: 2px solid #D0D5DD;
        &::-webkit-scrollbar { 
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    &__sub-wrapper {
        display: flex;
        color: var(--navy-blue-navy-blue-500, #193560);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: initial;
    }
    &__items {
        padding-bottom: 16px !important;
        display: flex;
        gap: 4px;
        padding: 0 12px;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: -webkit-fill-available;
        border-bottom: 2px solid #ffffff;
        & > div:last-child {
            width: max-content;
        }
        & img {
            width: 14px;
        }
        & span {
            font-size: 1.3rem;
        }
    }
    &__border {
        height: 16px;
        background: #D0D5DD;
        border-radius: 8px;
        padding: 1px;
        &--active {
            width: 148px;
            height: 3px;
            background: #193560;
            margin-top: -7px !important;
			transition: 0.6s;
            border-radius: 8px;
        }
    }
}
