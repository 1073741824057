.modal-confirmation {
	padding: 24px;
	&__modal-body {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 2.4rem;
		text-align: center;
		gap: 4rem;

		.modal-title-label {
			margin-bottom: 1.2rem;
		}

		&__button-groups {
			gap: 2.4rem;
			justify-content: space-between;
			width: 100%;
			&-btn {
				width: 100%;
			}

			&-wrapper {
				width: 100%;
			}
		}
	}

	&__alert-icon-circle {
		width: 78px;
		height: 78px;
	}
}
