@import '../../../../colorvariable.scss';

.question-filter {
    &__title {
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        &__bold {
            font-weight: 600;
        }
        color: #667085;
    }
    &__mandatory-mark {
        &::after {
            content: "*";
            color: $bg-second-red;
            font-size: 1rem;
        }
    }
}