.candidate-management {
	.job-ads-listing {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		&.white-background-div {
			margin-top: 0;
			padding: 1.6rem 2.4rem 1.6rem 2.4rem;
		}
	}
}
