.status-history {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__details {
        justify-content: space-between;
        padding: 12px;
        background-color: #F9FAFB;
        border-radius: 8px;
        border: 1px solid #CCDAEF;

        & > div:first-child {
            gap: 8px;
        }
    }
    &__description {
        gap: 8px;
        color: var(--Black, #1D2939);
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 140%;
        &--middle-text {
            color: var(--gray-gray-500, #667085);
            font-weight: 400 !important;
        }
    }
    .__time {
        color: var(--gray-gray-500, #667085);
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 140%;
    }
}