@import "../../../colorvariable.scss";

@media screen and (max-width: 960px) {
	.activeComponent {
		width: calc(100% - 10rem);
		margin-left: 10rem;
	}
	.navigation-div {
		* {
			margin: 0;
			padding: 0;
		}
	}
}

@media screen and (min-width: 960px) {
	.activeComponent {
		width: calc(100% - 26rem);
		margin-left: 26rem;

		&.collapse-sidebar {
			width: calc(100% - 10.1rem);
			margin-left: 10.1rem;
		}
	}
	// .collapse-sidebar {
	// 	margin-left: 20rem;
	// }
	.navigation-div {
		width: 26rem;
		// width: calc(100% - 10rem);
		.collapse {
			display: flex;
			gap: 1.2rem;
			align-items: center;
			border-radius: 6px;
			border: 1px solid var(--navy-blue-navy-blue-100, #d1d7df);
			background: var(--navy-blue-navy-blue-500, #193560);
			width: calc(100% - 33px);
			padding: 0.8rem 1.2rem;
			color: white;
			margin-bottom: 4rem;
			margin-top: 1.6rem;
		}
	}
}

.activeComponent {
	height: 100vh;
	padding-top: 7rem;
}

.navigation-div {
	background: $bg-primary;
	height: 100vh;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;

	.list-icon {
		margin-bottom: 4rem;
	}

	&.collapse-sidebar {
		width: 10rem;
		// transition: all ease-in-out 0.1s;
	}
	// z-index: 4;
	.logo {
		margin: 32px auto;
	}
	.sidebar-items-div {
		display: flex;
		flex-direction: column;
		transition: all ease-out 0.1s;
		min-width: 70px;
		width: calc(100% - 33px);
		// flex: 1;

		&.collapse-sidebar {
			display: flex;
			width: 6rem;
			transition: all ease-in-out 0.1s;
			align-items: flex-start;
		}

		.sidebar-item:hover {
			background-color: $bg-primary-hover;
			border-radius: 6px;
		}

		.sidebar-item {
			display: flex;
			align-items: center;
			margin-top: 16px;
			padding: 8px;
			cursor: pointer;
			color: white;
			border-radius: 6px;
			// width: 8rem;
			// width: calc(100% - 33px);
			// flex: 1;

			&.collapsed {
				justify-content: center;
				width: 100%;
			}

			&.active {
				background-color: $bg-primary-hover;
			}

			&.post-job-menu {
				background-color: #ffffff;
				color: $label-color-blue;
			}

			.menu-name {
				margin: 0;
				align-items: center;
				margin-left: 15px;
			}
		}
	}
}
