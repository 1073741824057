@import "./../../colorvariable.scss";
@import "./../../variable.scss";
@import "./../../mixin.scss";

.jc {
	background-color: $bg-input-disable;
	height: calc(100vh - 8rem);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow-y: auto;

	.light-text {
		color: $btn-primary-disable;
	}

	&-wrapper {
		width: 75.6rem;
		margin: 2rem auto;

		h2 {
			font-weight: 500;
			margin-top: 3.2rem;
			margin-bottom: 2.4rem;
		}

		h3 {
			font-size: 1.6rem;
			font-weight: 600;
			margin-bottom: 0.8rem;
		}

		.form-row {
			display: flex;
			align-items: center;
			gap: 1.2rem;
		}

		.btn-grp {
			display: flex;
			gap: 1.6rem;

			.customBtn {
				padding: 1.2rem 2rem;
			}

			.icon-prev {
				margin-right: 0.8rem;
			}

			.btn-save {
				color: $white-color;
			}
		}

		.form-block {
			display: flex;
			flex-direction: column;
			gap: 2.4rem;
		}

		.job-type-container {
			display: flex;
			gap: 1.2rem;
			flex-wrap: wrap;
		}

		.job-type-input {
			width: 28.3rem;

			input {
				padding-right: 1.2rem !important;
			}
		}

		.badge {
			outline: 1.5px solid $btn-text-gray-default;
			border-radius: 8px;
			width: max-content;
			gap: 0.8rem;
			padding: 0.8rem 1.2rem;
			color: $gray-500;
			cursor: pointer;
			@include flex-all-center;

			&-selected {
				background: #e9ebf0;
				outline-width: 2px;
				color: $btn-primary-default;
			}

			&-disabled {
				outline-color: $border-bottom-dropdown;
				color: $border-bottom-dropdown;
				pointer-events: none;
			}

			&:hover {
				background: $btn-outline-hover;
			}
			p {
				margin-bottom: 0;
				font-weight: 600;
			}
		}

		.info-banner {
			padding: 0.8rem;
			border-radius: 4px;
			display: flex;
			align-items: center;
			gap: 1rem;
			font-size: 1.4rem;
			font-weight: 500;
		}

		.field-warning {
			background: $bg-warning-transparent;
			color: $secondary-500;
		}

		.field-error {
			background: $error-50;
			color: $btn-error-hover;
		}

		.field-info {
			background: #e9ebf0;
			color: $btn-primary-default;
			margin-top: 2.4rem;
		}

		.titleAndCity {
			.location__search {
				position: relative;

				.J-ddComp-menu {
					max-height: 20rem;
					overflow-y: auto;
					position: absolute;
					z-index: 4;
					border-radius: 8px;
					padding: 0;

					& .menuDD {
						:hover {
							cursor: pointer;
							background: rgb(202, 202, 202);
						}

						p {
							width: 100%;
							padding: 0.7rem 1rem;
							margin-bottom: 0;
						}
					}
				}
			}
		}
		.salary,
		.emp-assist {
			.tComp {
				&-content {
					display: flex;
					align-items: center;
				}
				&-text {
					padding: 0 !important;
					margin-bottom: 0;
				}
				&-switch {
					margin: 0;
					padding: 0;

					.toggle-switch {
						margin-left: 1.6rem;
					}

					label {
						transform: unset;
						height: 2.4rem;
						width: 4.4rem;

						&::after {
							height: 2rem;
							width: 2rem;
							top: 0.15rem;
						}
					}
				}
			}

			.to-sep {
				margin-top: 3rem;
			}
		}
		.urgency {
			.hires-header {
				h3 {
					font-size: 1.6rem;
					font-weight: 600;
				}
				p {
					font-size: 1.4rem;
					font-weight: 400;
					margin-bottom: 0;
				}
			}

			.hires {
				position: relative;

				.job-type-input {
					width: 8rem;
					input {
						text-align: center;
					}
				}

				.icon {
					cursor: pointer;

					&-disable {
						color: $btn-primary-disable;
						pointer-events: none;
					}

					&-active {
						color: $btn-primary-default;
					}
				}
			}
			.warning {
				span {
					color: $btn-error-default;
				}
			}
		}

		.prescreen {
			&-header {
				p {
					font-size: 1.4rem;
				}
			}

			.questions-type {
				p {
					font-size: 1.4rem;
				}
			}

			.questions {
				display: flex;
				flex-direction: column;
				gap: 2.4rem;

				.question {
					overflow: hidden;
					border: 1px solid $border-input;
					border-radius: 8px;

					&.error {
						border: 2px solid $btn-error-hover;
					}

					&-header {
						background: $btn-outline-hover;
						padding: 1.6rem;

						display: flex;
						align-items: flex-start;
						justify-content: space-between;

						h3 {
							font-size: 1.8rem;
						}

						p {
							margin-bottom: 0;
							font-size: 1.6rem;
							font-weight: 400;
						}

						&-icons {
							display: flex;
							gap: 2rem;
						}
					}

					&-content {
						padding: 1.6rem;
						.text {
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 1.2rem;

							p {
								font-weight: 600;
							}

							label {
								white-space: nowrap;
							}

							.J-inputComp-choice {
								margin-bottom: 0;
							}
						}
					}

					.custom-question-content {
						.custom-question-input {
							margin-top: 2.4rem;
						}
					}

					.custom-options {
						background: $gray-100;
						padding: 1.2rem;
						border-radius: 4px;
						border: 1px solid $border-input;

						h4 {
							font-size: 1.6rem;
							margin-bottom: 1.2rem;
						}

						&-container {
							display: flex;
							flex-direction: column;
							gap: 1.2rem;
						}

						.option {
							background: $white-color;
							width: 100%;
							border: 1px solid $border-input;
							border-radius: 4px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							overflow: hidden;

							.inp-container {
								padding-inline: 1.2rem;
								outline: 1px solid $border-input;
								display: flex;
								flex: 1;
								align-items: center;
								gap: 1rem;
								position: relative;

								.custom-check {
									.J-inputComp-choice {
										margin-bottom: 0;
										gap: 0;
									}
								}

								.custom-radio {
									background: $white-color;
									height: 1.5rem;
									width: 1.6rem;
									border-radius: 50%;
									outline: 1px solid $gray-500;
									cursor: pointer;

									&.active {
										background-color: $btn-primary-default;
										outline-width: 2px;
										outline-offset: 2px;
									}
								}

								.custom-option-input input {
									flex: 1;
									border: none;
									border-radius: 0;
									padding: 1.2rem 0.5rem !important;
									font-weight: 500;
									font-size: 1.5rem;
								}
							}

							.icon {
								width: 5rem;
								padding-inline: 0.8rem;
								text-align: center;
								cursor: pointer;
							}
						}

						.add-option-btn button {
							padding: 1.2rem !important;
							color: $btn-primary-default;
							font-size: 1.6rem;

							&:disabled {
								color: $btn-primary-disable;
								text-decoration: none;
								cursor: not-allowed;
							}
						}
					}

					.preview-btn {
						// margin-top: 1.5rem;

						button {
							padding: 0.8rem 1.2rem;
						}
					}
				}
			}
		}

		.emp-assist {
			&-toggle {
				.tComp-text {
					font-size: 2rem !important;
					font-weight: 600;
					margin-bottom: 0;
					color: $text-black;
				}
			}

			&-desc {
				font-size: 1.4rem;
				color: $gray-500;
			}

			.emp-decline-days {
				background: $bg-input-disable;
				padding: 1.6rem;
				border-radius: 8px;
				border: 1px solid $border-input;
				text-align: center;

				.no-interest-text {
					margin-bottom: 2.4rem;
					font-weight: 600;
				}
				.days-container {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 1.2rem;
					flex-wrap: wrap;

					.day-card {
						height: 13rem;
						width: 11rem;
						background: $white-color;
						border: 1px solid $border-input;
						border-radius: 4px;
						padding: 1.2rem 1.6rem;
						cursor: pointer;
						position: relative;
						flex-direction: column;
						gap: 0.8rem;
						@include flex-all-center;

						&.active {
							background: $btn-primary-hover;
							color: $white-color;
						}

						p {
							margin-bottom: 0;
						}

						.day {
							font-size: 2rem;
							font-weight: 600;
							text-transform: capitalize;
						}
						.text {
							font-size: 1.6rem;
							font-weight: 400;
						}

						.icon-pencil {
							position: absolute;
							right: 5px;
							top: 5px;
						}
					}
				}
			}
		}

		.schedule {
			.date-time-container {
				margin-top: 1.8rem;

				.isRequired::after {
					content: "*";
					color: $btn-error-default;
				}

				.date {
					p {
						font-weight: 500;
						font-size: 1.4rem;
						transition: all 0.1s ease-in;
						margin-bottom: 0.8rem;
						color: $btn-text-gray-default;
					}

					.schedule-date {
						border-color: $btn-primary-default;
						overflow: hidden;

						display: flex;
						align-items: center;
						padding-left: 1.2rem;

						&-disabled {
							background: #ece9ef;
							cursor: not-allowed;
						}

						&.border-red {
							border-color: $btn-error-default;
						}

						input {
							width: 100%;
							border: none;
							border-radius: unset;
							padding-left: 1rem;
							padding-block: 1.2rem;
							font-size: 1.7rem;
							font-family: inherit;
							font-weight: 500;

							&:focus {
								box-shadow: none;
							}

							&:disabled {
								cursor: not-allowed;
							}
						}
					}
				}
			}
		}

		// PREVIEW JOB
		.preview-job {
			flex-grow: 1;
			min-width: 0;
			color: $btn-text-gray-default;

			.jb-cont {
				padding: 3.2rem;
				background-color: white;
				border: 1px solid $border-input;
				border-radius: 1.6rem;

				.jl-company-logo img {
					width: 6rem;
					height: 6rem;
					object-fit: contain;
					border-radius: 0.8rem;
				}

				& .detail {
					font-size: 1.4rem !important;
					font-family: "Noto Sans" !important;

					p {
						font-size: inherit;
					}
				}
			}

			.jl-job-name {
				color: $label-text-black;
				font-weight: 600;
				font-size: 2rem;
			}
			.jl-company-name {
				color: $btn-primary-default;
				font-size: 1.6rem;
				font-weight: 500;
			}
			.jl-city-name {
				color: $gray-500;
				font-size: 1.6rem;
				font-weight: 400;
			}

			.job-info-details-grid {
				display: grid;
				width: 100%;
				grid-template-columns: 1fr 1fr;
				gap: 1.2rem;
			}
		}
	}
}

// Form
.jc-form {
	padding: 2.4rem;
	border: 1px solid #ccdaef;
	border-radius: 1.2rem;
	background: #fff;

	h4 {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2.4rem;

		&.isRequired::after {
			content: "*";
			color: $btn-error-default;
		}
	}

	&-body {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
	}
}

// Progress Bar
.progress-bar {
	.RSPBprogressBar {
		height: 2rem !important;

		.RSPBprogression {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}

	&-default {
		h4 {
			font-size: 1.6rem;
			margin-top: 1.6rem;
			margin-bottom: 3.2rem;
			font-weight: 600;
		}
	}
}
