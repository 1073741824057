@import "./../../colorvariable.scss";
@import "./../../mixin.scss";

.job-searching {
  .filter-cont {
    display: flex;
    gap: 0.8rem;
    @include hide-scrollbar;
  }

  .listing-count {
    @include text-sm;
    font-weight: 600;
    color: $btn-text-gray-default;
  }

  .job-listing {
    width: 45rem;
    min-width: 45rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
      min-width: 100%;
    }
  }

  .job-info {
    flex-grow: 1;
    min-width: 0;
    padding: 0 2rem;
    border-radius: 0.8rem;
    border: 1px solid $border-input;
    position: sticky;
    top: 0.8rem;
    height: max-content;
    overflow: auto;
    color: $btn-text-gray-default;

    @media only screen and (max-width: 768px) {
      display: none;
    }

    & > *:first-child {
      margin-top: 2rem;
    }

    & > *:last-child {
      margin-bottom: 2rem;
    }

    &-btn-cont {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      width: 100%;
      background-color: white;
    }

    &-sticky-top {
      position: sticky;
      top: 0;
      padding-top: 2rem;
      background-color: white;
    }
  }

  .jl-company-name {
    font-weight: 600;
    color: $text-black;
  }

  .jl-company-logo,
  .jl-company-logo img {
    width: 6rem;
    height: 6rem;
    object-fit: contain;
    border-radius: 0.8rem;
  }
}

.blue-header {
  background-color: $btn-primary-default;
  padding: 3.2rem 0;
}