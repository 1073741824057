.small-pagination {
    &__wrapper {
        display: flex;
        gap: 16px;
        margin-right: 15px;
    }
    &__text {
        & span {
            color: var(--gray-gray-700, #344054);
            font-size: 0.875em;
            font-weight: 500;
            opacity: 0.5;
        }
    }
    &__arrow-wrapper {
        display: flex;
        & img {
            width: 25px;
            padding: 6px;
        }
        & img:hover {
            border-radius: 50%;
            background: #eaeaea;
        }
        & img.disabled:hover {
            border-radius: none;
            background: none;
            cursor: default;
        }
    }
}