@import '../../../../colorvariable.scss';

// Container
.form-screening {
  position: relative;
  border-radius: 1rem;
  margin-top: 2.4rem;
  border: 0.1rem solid $border-input;

  //   ============== Heading Section ==============
  &__heading {
    background-color: $bg-progress-bar;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.6rem; /*16px*/
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  //  ============== End Heading Section ==============

  //   ============== Content Section ==============
  &__content {
    padding: 2.4rem 1.6rem; /* 24rem 16px*/
    border-radius: 1rem;
  }
  //  ============== End Content Section ==============
}
