.phone-input-wrapper {
	display: flex;
	border: 1px solid #ccdaef;
	padding: 1.2rem;
	align-items: center;
	border-radius: 0.8rem;

	&.error-bor {
		border: 1px solid #f04438;
	}

	.warning-icon {
		filter: brightness(0) saturate(100%) invert(32%) sepia(22%) saturate(7288%)
			hue-rotate(344deg) brightness(105%) contrast(88%);
		width: 25px;
		height: 25px;
	}

	input {
		border: none;
		outline: none;
		width: 100%;
	}
	&__select-country {
		border: none;
		outline: none;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		width: 55px;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent;
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='16' height='16' viewBox='0 0 256 256'><path d='M213.66 101.66l-80 80a8 8 0 01-11.32 0l-80-80a8 8 0 0111.32-11.32L128 164.69l74.34-74.35a8 8 0 0111.32 11.32z'/></svg>");
		background-repeat: no-repeat;
		background-position-x: 100%;
		background-position-y: 50%;
	}

	.country-select {
		display: flex;
		align-items: center;
		position: relative;

		.icon-caret {
			position: absolute;
			right: 5px;
		}
	}

	& .PhoneInputInput {
		border: none;

		&:focus {
			outline: none;
		}
	}
}

.PhoneInputCountry {
	display: none;
}
