@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./colorvariable.scss";
@import "./mixin.scss";

// $notosans-fontface-path: "fonts/Noto/";
// @import "notosans-fontface/scss/notosans-fontface";

// --------- CSS RESET STARS HERE --------- //
// ---- Please refrain changing this unless necessary ---- //
:root {
	width: 100%;

	--bs-body-color: $label-color-dark-gray-2;
	--toastify-toast-width: 62.6rem;
}

:host {
	font-family: "Noto Sans", sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	margin: 0;
	font-family: "Noto Sans", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

#root,
#__next {
	isolation: isolate;
}

html {
	// 1 REM = 10 PX
	// Notice that we use different rem value as portal sekolah app
	font-size: 62.5%;

	@media only screen and (max-width: 960px) {
		font-size: 50%;
	}
}

body,
.btn {
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 140%;
	color: $text-black;
	background: $gray-50;
}

html,
body {
	height: 100%;
}

// table tr th,
// table tr td {
// 	border: 1px solid black;
// 	padding: 0.5rem 1rem;
// }

// table tr th {
// 	background-color: $bg-container;
// 	font-size: 1.5rem;
// }
.Toastify__toast {
	gap: 1.6rem;
	padding: 1.2rem 1.6rem;
	border-radius: 0.8rem;
	border: 2px solid;
	&-body {
		gap: 1.6rem;
		padding: 0;
		font-size: 1.4rem;
		& > div:nth-child(2)::before {
			font-weight: bold;
			display: block;
			font-size: 1.6rem;
		}
	}
	&-icon {
		width: 4.8rem;
		margin-inline-end: 0;
	}
	&.Toastify__toast-theme--light.Toastify__toast--info,
	&.Toastify__toast-theme--light.Toastify__toast--success {
		background-color: #ecfdf3;
		color: #039855;
		border-color: #039855;

		& > .Toastify__toast-body div:nth-child(2)::before {
			// content: "Success";
			color: #027a48;
		}

		& > .Toastify__toast-body div:last-child {
			margin-top: 2rem;
		}
	}
	&.Toastify__toast-theme--light.Toastify__toast--warning {
		background-color: #fffaeb;
		color: #dc6803;
		border-color: #dc6803;

		& > .Toastify__toast-body div:nth-child(2)::before {
			// content: "Warning";
			color: #b54708;
		}

		& > .Toastify__toast-body div:last-child {
			margin-top: 2rem;
		}
	}
	&.Toastify__toast-theme--light.Toastify__toast--error {
		background-color: #fef3f2;
		color: #d92d20;
		border-color: #d92d20;

		& > .Toastify__toast-body div:nth-child(2)::before {
			// content: "Error";
			color: #b42318;
		}

		& > .Toastify__toast-body div:last-child {
			margin-top: 2rem;
		}
	}
	.J-iconComp-content {
		padding: 1.2rem;
	}
}

// Inline Padding multiple of four
.padTop-16 {
	padding-top: 1.6rem;
}
.padBot-16 {
	padding-bottom: 1.6rem;
}
.padBot-24 {
	padding-bottom: 2.4rem;
}
.padX-44 {
	padding-left: 4.4rem !important;
	padding-right: 4.4rem !important;
}
.padTop-32 {
	padding-top: 3.2rem;
}
.padBot-32 {
	padding-bottom: 3.2rem;
}
.marLeft-auto {
	margin-left: auto;
}

.marRight-auto {
	margin-right: auto;
}

.borRad-left {
	border-top-left-radius: 0.8rem;
}

.borRad-right {
	border-top-right-radius: 0.8rem;
}

.wsNoWrap {
	white-space: nowrap;
}

.rotate180d {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.toggle-switch,
.toggle-switch {
	transform: scale(1.2);

	&.modal-togle {
		transform: scale(1.2);
		border: 2x solid black;
	}
}

.toggle-switch {
	cursor: pointer;
	text-indent: -9999px;
	width: 25px;
	height: 18px;
	background: #bec4cf;
	display: block;
	border-radius: 100px;
	position: relative;
	margin-bottom: 0;
	// &.revert {
	// 	transform: scale(-1.2);
	// }
}

.toggle-switch:after {
	content: "";
	position: absolute;
	top: 2.8px;
	left: 2px;
	width: 12px;
	height: 12px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + .toggle-switch {
	background: #1abf73;
}

input:checked + .toggle-switch:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.togle-switch {
	display: none;
}
.toggle-switch {
	width: 34px;
	height: 18px;
	margin-left: 24px;

	&::after {
		width: 12.5px;
		height: 12.5px;
	}
}
input:checked + .toggle-switch {
	background: $navy-blue-500;
}
.status-dropdown {
	border: 1px solid #ccdaef;
	border-radius: 8px;
	padding: 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	span {
		font-weight: 400;
		color: #98a2b3;
		width: 152px;
		&.color-black {
			color: #1d2939;
		}
	}
	i {
		font-size: 25px;
		color: #667085;
		margin-left: 20px;
	}
}
.hn-dropdown {
	z-index: 9999;
}
.horizontal-line {
	height: 1px;
	border-top: 1px solid #d0d5dd;
}
.candidate-checkbox {
	width: 16px;
	height: 16px;
	margin-top: 9px;
}
.accordion {
	--bs-accordion-active-bg: white;
}
.accordion-item {
	border: none;
}
.accordion-button {
	padding: 0;

	&:not(.collapsed) {
		box-shadow: none;
	}
}
.accordion-button::after {
	display: none;
}

// --------- CSS RESET ENDS HERE --------- //

// --------- Styling declarations --------- //

h1 {
	font-size: 4rem;
}
h2 {
	font-size: 3rem;
}
h3 {
	font-size: 2rem;
}
h4 {
	font-size: 1.5rem;
}
h5 {
	font-size: 1rem;
}
p,
span,
button {
	font-size: 1.6rem;
}

.font-italic {
	font-style: italic;
}

#root {
	background-color: white;
}

.flex-all-center {
	@include flex-all-center;
}

.text-label-blue-gradient {
	background: linear-gradient(265.71deg, #1e63be 0%, #4a8de5 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}
.text-label-primary {
	color: $btn-primary-default !important;
}
.text-label-dark-blue {
	color: $label-color-dark-blue !important;
}
.text-label-black {
	color: $text-black !important;
}
.text-label-gray {
	color: $btn-text-gray-default !important;
}
.text-label-dark-gray {
	color: $label-color-dark-gray !important;
}
.text-label-dark-gray-2 {
	color: $label-color-dark-gray-2 !important;
}

.font-weight-medium {
	font-weight: 500;
}
.font-weight-semibold {
	font-weight: 600;
}

.display-xl {
	font-size: 7.2rem !important;
}
.display-2xl {
	font-size: 6rem !important;
}
.display-lg {
	font-size: 4.8rem !important;
}
.display-md {
	font-size: 3.6rem !important;
}
.display-sm {
	font-size: 3rem !important;
}
.display-xs {
	font-size: 2.4rem !important;
}
.text-xl {
	font-size: 2rem !important;
}
.text-lg {
	font-size: 1.8rem !important;
}
.text-md {
	font-size: 1.6rem !important;
}
.text-sm {
	font-size: 1.4rem !important;
}
.text-xs {
	font-size: 1.2rem !important;
}

.btn-cta-primary,
.btn-cta-secondary {
	border-radius: 0.8rem;
	font-weight: 700;
	padding: 1.2rem 1.6rem;
	transition: 100ms;
	font-size: 1.5rem;

	.btn-icon {
		width: 2.4rem;
		height: 2.4rem;
		margin-right: 1.2rem;
		position: relative;
		display: inline-flex;
		vertical-align: middle;

		@include icon-standby-hover-def;
	}

	&.btn-sm {
		font-size: 1.2rem;
		padding: 0.8rem 1.2rem;

		.btn-icon {
			width: 1.6rem;
			height: 1.6rem;
			margin-right: 0.8rem;
		}
	}
}

.btn-cta-primary {
	color: white;
	background: linear-gradient(265.71deg, #1e63be 0%, #4a8de5 100%);

	&:focus,
	&:hover {
		background: linear-gradient(265.71deg, $blue-primary 0%, $blue-primary 100%);
		color: white;
	}
	&:active {
		color: white;
		background: linear-gradient(265.71deg, $label-color-blue 0%, $label-color-blue 100%);
		border: 1px solid $bg-container;
		outline: 1px solid $bg-container;
	}
	&:disabled {
		color: $label-color-blue;
		border: 1px solid var(--primary-navy-blue-200, #a3aebf);
		background: var(--primary-navy-blue-200, #a3aebf);
	}
}

.btn-cta-secondary {
	color: $bg-light-blue;
	border: 1px solid $bg-light-blue;

	&:focus,
	&:hover {
		border: 1px solid $bg-light-blue;
		background: $bg-container;
		color: $bg-light-blue;
		@include icon-hover-show;
	}
	&:active {
		background: #056cf2;
		color: white;
	}
	&:disabled {
		color: $label-color-gray;
		border-color: $label-color-gray;
		background: $bg-second-light;
	}
}

.btn-show-more {
	@include flex-all-center;
	width: 100%;
	padding: 1.2rem;
	font-size: 1.4rem;
	color: $bg-dark-blue;
	font-weight: 500;
	border-radius: 0.8rem;

	&:hover {
		background-color: darken($bg-container, 5%);
	}
}

.cursorPointer {
	cursor: pointer;
}

.form-group {
	margin-bottom: 2.4rem;

	label {
		margin-bottom: 0.8rem;
		font-weight: 500;
		font-size: 1rem;
	}

	.form-control {
		border: 1px solid #d0d5dd;
		padding: 1.6rem;
		border-radius: 0.8rem;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: $label-color-dark-gray-2;
		font-weight: 500;

		&::placeholder {
			color: $label-color-gray;
		}
		&:active,
		&:focus {
			border-color: $label-color-dark-blue;
			outline: none;
			box-shadow: none;
		}
		&.error {
			border-color: $bg-orange;
		}
	}

	.error-txt {
		font-size: 1rem;
		margin-top: 0.8rem;
		color: $bg-orange;
	}

	.pwd-eye {
		position: absolute;
		padding: 1rem;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.form-check-input:checked[type="radio"] {
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%230d6efd'/%3e%3c/svg%3e");
}

.divider {
	width: 100%;
	margin: 2.4rem 0;
	height: 1px;
	background-color: $border-input;
}

.vertical-divider {
	width: 2px;
	margin: 0 2.4rem;
	height: 100%;
	background-color: $border-bottom-dropdown;
	padding: 0;
}

.error-msg {
	width: 100%;
	padding: 0.8rem 1.2rem;
	background-color: $light-red;
	font-size: 1.5rem;
	font-weight: 700;
	color: $bg-orange;
	margin-bottom: 2.4rem;
	border-radius: 0.8rem;
}

.search-bar {
	height: 4.8rem;
	flex: 1;
	position: relative;

	input {
		width: 100%;
		height: 100%;
		padding: 1.55rem 1.2rem 1.55rem 4.8rem;
		line-height: 1.5;
		border-radius: 0.8rem;
		background-color: $bg-container;
		border: 1px solid $light-dark-green;
		transition: 50ms;
		outline: none;
		color: $label-color-dark-gray-2;

		&:focus,
		&:active,
		&:not(:placeholder-shown) {
			background-color: white;
		}

		&:focus {
			background-color: white;
			border-color: $bg-light-blue;
		}
	}

	.searchIcon {
		position: absolute;
		left: 1.2rem;
		top: 50%;
		transform: translateY(-50%);
	}
}

.placeholder-glow .placeholder {
	animation: Gradient 2.25s ease infinite;
}

.placeholder {
	border-radius: 0.6rem;
	margin-right: 0.5rem;
	background-color: #f1efef;
}

.pulsate {
	background: linear-gradient(90deg, #f1efef, #f9f8f8, #f1efef, #f9f8f8);
	background-size: 400% 400%;
	animation: Gradient 2.25s ease infinite;
	cursor: wait;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.mw-0 {
	min-width: 0;
}
.mw-std {
	min-width: 1.6rem;
}
.mw-2p4rem {
	min-width: 2.4rem;
}
.mh-0 {
	min-height: 0;
}
.w-0 {
	width: 0;
	text-wrap: nowrap;
	visibility: hidden;
}

.facilities-cont {
	display: grid;
	gap: 1.6rem;
	grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));

	&-item {
		padding: 1.6rem;
		color: $label-color-dark-gray-2;
		font-size: 1.5rem;
		box-shadow: 0px 4px 4px 0px #056cf20d;
		border: 1px solid #f2f7ff;
		font-weight: 500;
		border-radius: 0.8rem;
		background-color: white;
		padding-left: 3rem;
		text-indent: -2rem;

		&:before {
			content: "";
			display: inline-block;
			margin-right: 1rem;
			margin-top: 0.625rem;
			width: 1rem;
			height: 1rem;
			min-width: 1rem;
			border-radius: 50%;
			background-color: $bg-second-orange;
		}
	}

	&-item.no-before {
		padding: 2.2rem 1.6rem;
		text-align: center;
		padding-left: 0;
		text-indent: 0;

		&::before {
			content: "";
			display: inline-block;
			margin-right: 0;
			width: 0;
			height: 0;
			border-radius: 0;
			margin-top: 0;
			background-color: transparent;
		}
	}
}

.fav-button {
	padding: 1.2rem 1.6rem;
	box-shadow: 0px 6px 10px 0px #056cf20d;
	background-color: white;
	border: 1px solid $bg-container;
	border-radius: 0.8rem;
	color: $label-color-dark-gray-2;
}

.full-width-banner {
	width: 100%;
	height: 100%;
	max-height: 75vh;
	object-fit: cover;
}

.back-arrow {
	transform: rotate(225deg);
}
.down-arrow {
	transform: rotate(145deg);
}

.quote-cont {
	width: 100%;

	@media screen and (min-width: 640px) {
		max-width: 64rem;
	}
}

.quote-desc {
	position: relative;
	font-size: 1.6rem;

	p {
		font-size: 1.6rem;
	}

	&::before {
		content: "‟";
		position: absolute;
		font-size: 10rem;
		color: $label-color-dark-blue;
		top: -5rem;
		left: -6rem;
	}
}
section.detail-section {
	padding: 2.4rem 0.75rem;
	margin-bottom: 8rem;

	.header {
		font-weight: 700;
		color: $label-color-dark-gray-2;
		margin-bottom: 3.2rem;
	}
}

.as-label {
	font-weight: 500;
	color: $btn-text-gray-default;
	padding-bottom: 0.8rem;
	font-size: 1.4rem !important;

	&.isRequired::after {
		content: "*";
		color: $btn-error-default;
	}
}

.btn-scrollTop {
	width: 4.8rem;
	height: 4.8rem;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	z-index: 99;
	opacity: 1;
	visibility: visible;
	transition: 200ms;
	background-color: $label-color-blue;
	border-color: $label-color-blue;
	border-radius: 0.8rem;
	box-shadow: 5px 1px 21px rgba(51, 51, 51, 0.3);

	&.hide {
		visibility: hidden;
		opacity: 0;
	}
}

.caret-top {
	transform: rotate(180deg);
}

// Breadcrumbs
.breadcrumb {
	align-items: center;
	color: $label-color-dark-gray-2;
}
.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.8rem;
	&::before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
		padding-right: 0.8rem;
	}
}
.breadcrumb-item.active {
	font-size: 1.5rem;
	font-weight: 700;
	color: $bg-dark-blue;
}

// Tabs
.nav.nav-tabs-custom {
	gap: 0.8rem;
	flex-wrap: nowrap;
	max-width: 100%;
	overflow: auto;
	@include hide-scrollbar;
	.nav-custom.nav-link {
		border-radius: 0.8rem 0.8rem 0 0;
		border: 1px solid $active;
		padding: 1.6rem;
		font-size: 1.5rem;
		line-height: 1.8rem;
		font-weight: 700;
		color: $blue-primary;
		min-width: 13rem;
		width: max-content;
		text-align: center;
		position: relative;
		border-bottom: 0;

		&.active {
			background-color: white;
			border-color: $bg-second-light;
			&:after {
				content: "";
				position: absolute;
				background-color: white;
				z-index: 1;
				width: 100%;
				bottom: -2px;
				left: 0;
				height: 4px;
			}
		}
	}
}
.tab-content-custom.tab-content {
	padding: 4rem;
	background-color: white;
	border-radius: 0 0.8rem 0.8rem 0.8rem;
	border: 1px solid $bg-second-light;
}

//tooltip
.custom-tooltip {
	position: relative;
	// display: flex;

	// Bootstrap modal being a bruh momentum
	z-index: 1100;

	&.show {
		opacity: 1;
	}

	.tooltip-inner {
		height: max-content;
		width: max-content;
		max-width: 25rem;

		background-color: $label-color-dark-gray;
		color: white;

		font-weight: 500;
		font-size: 0.85rem;

		border-radius: 0.5rem;
		padding: 0.5rem 1rem;
		text-align: left;
	}

	&.long .tooltip-inner {
		max-width: 50rem;
	}

	&.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
	&.bs-tooltip-bottom .tooltip-arrow::before {
		border-bottom-color: $label-color-dark-gray;
	}
	&.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
	&.bs-tooltip-top .tooltip-arrow::before {
		border-top-color: $label-color-dark-gray;
	}
	&.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
	&.bs-tooltip-start .tooltip-arrow::before {
		border-left-color: $label-color-dark-gray;
	}
	&.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
	&.bs-tooltip-end .tooltip-arrow::before {
		border-right-color: $label-color-dark-gray;
	}

	span {
		display: block;
	}
}

// Responsiveness
.show-desktop {
	@media only screen and (max-width: 960px) {
		display: none !important;
	}
}
.show-mobile {
	@media only screen and (min-width: 960px) {
		display: none !important;
	}
}
.d-flex-desktop {
	display: flex;
	@media only screen and (max-width: 960px) {
		flex-direction: column;
		gap: 1rem;
	}
}

// WYSIWYG Force styling
.force-styling-wysiwyg {
	& * {
		font-family: "Montserrat", sans-serif !important;
		line-height: 1.75;
	}
	& *:not(strong):not(b) {
		font-weight: 500;
	}
	& *:not(h1):not(h2):not(h3):not(h4):not(h5) {
		font-size: 1.6rem !important;
	}
	h1 {
		font-size: 4.5rem !important;
	}
	h2 {
		font-size: 3.5rem !important;
	}
	h3 {
		font-size: 2.5rem !important;
	}
	h4 {
		font-size: 2.1rem !important;
	}
	h5 {
		font-size: 1.6rem !important;
	}
	p,
	ul,
	ol,
	address,
	pre {
		margin-bottom: 1.75rem;
	}
	table {
		margin-left: auto;
		margin-right: auto;
	}
}
.force-font-wysiwyg {
	& * {
		font-family: "Montserrat", sans-serif !important;
		line-height: 1.75;
	}
	& *:not(strong):not(b) {
		font-weight: 500;
	}
	p,
	ul,
	ol,
	address,
	pre {
		margin-bottom: 1.75rem;
	}
	table {
		margin-left: auto;
		margin-right: auto;
	}
}

.positionRelative {
	position: relative;
}

// --------- Dynamic class generation starts here --------- //

/*
	Sizes:
	sm: 1.2rem, md: 2.4rem, lg: 3.2rem
	you can use something like .me-sm to put margin-right 1.2rem
*/
$sizes: (
	"xxs": 0.4rem,
	"xs": 0.8rem,
	"sm": 1.2rem,
	"std": 1.6rem,
	"med": 1.8rem,
	"md": 2.4rem,
	"lg": 3.2rem,
	"xl": 4.2rem,
);

$textSizes: (
	"xs": 1.2rem,
	/* 12px */ "sm": 1.4rem,
	/* 14px */ "md": 1.6rem,
	/* 16px */ "lg": 1.8rem,
	/* 18px */ "xl": 2rem,
	/* 20px */ "display-xs": 2.4rem,
	/* 24px */ "display-sm": 3rem,
	/* 30px */ "display-md": 3.6rem,
	/* 36px */ "display-lg": 4.8rem,
	/* 48px */ "display-xl": 6rem,
	/* 60px */ "display-2xl": 7.2rem,
	/* 72px */
);

// Automatically set margin and padding based on sizes
@each $name, $size in $sizes {
	.me-#{$name} {
		margin-right: #{$size} !important;
	}
	.ms-#{$name} {
		margin-left: #{$size} !important;
	}
	.mt-#{$name} {
		margin-top: #{$size} !important;
	}
	.mb-#{$name} {
		margin-bottom: #{$size} !important;
	}
	.mx-#{$name} {
		margin-left: #{$size} !important;
		margin-right: #{$size} !important;
	}
	.my-#{$name} {
		margin-top: #{$size} !important;
		margin-bottom: #{$size} !important;
	}
	.m-#{$name} {
		margin: #{$size} !important;
	}
	.pe-#{$name} {
		padding-right: #{$size} !important;
	}
	.ps-#{$name} {
		padding-left: #{$size} !important;
	}
	.pt-#{$name} {
		padding-top: #{$size} !important;
	}
	.pb-#{$name} {
		padding-bottom: #{$size} !important;
	}
	.px-#{$name} {
		padding-left: #{$size} !important;
		padding-right: #{$size} !important;
	}
	.py-#{$name} {
		padding-top: #{$size} !important;
		padding-bottom: #{$size} !important;
	}
	.p-#{$name} {
		padding: #{$size} !important;
	}
	.gap-#{$name} {
		gap: #{$size} !important;
	}
	.rounded-#{$name} {
		border-radius: #{$size} !important;
	}
}

// Set sizes for typhography
@each $name, $size in $textSizes {
	.text-#{$name} {
		font-size: #{$size} !important;
	}
}

// --------- Dynamic class generation ends here --------- //

.tw {
	&.transition {
		transition-property: color, background-color, border-color, text-decoration-color, fill,
			stroke, opacity, box-shadow, transform, filter, backdrop-filter;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 150ms;
	}
	&.transition-all {
		transition-property: all;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 150ms;
	}
	&.duration-100 {
		transition-duration: 100ms;
	}
	&.duration-200 {
		transition-duration: 200ms;
	}
	&.duration-300 {
		transition-duration: 300ms;
	}
	&.duration-1000 {
		transition-duration: 800ms;
	}
	&.ease-in-out {
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	}
	&.ease-linear {
		transition-timing-function: linear;
	}
	&.ease-in {
		transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	}
	&.uppercase {
		text-transform: uppercase;
	}
	&.lowercase {
		text-transform: lowercase;
	}
	&.capitalize {
		text-transform: capitalize;
	}
	&.normal-case {
		text-transform: none;
	}

	&.rounded-lg {
		border-radius: 0.8rem; /* 16px */
	}

	&.rounded-t-lg {
		border-top-left-radius: 0.8rem; /* 16px */
		border-top-right-radius: 0.8rem; /* 16px */
	}

	&.rounded-t-xl {
		border-top-left-radius: 1.6rem; /* 16px */
		border-top-right-radius: 1.6rem; /* 16px */
	}
	&.rounded-b-xl {
		border-bottom-left-radius: 1.6rem; /* 16px */
		border-bottom-right-radius: 1.6rem; /* 16px */
	}

	&.rounded-l-xl {
		border-top-left-radius: 1.6rem; /* 16px */
		border-bottom-left-radius: 1.6rem; /* 16px */
	}

	&.rounded-bl-xl {
		border-bottom-left-radius: 1.6rem; /* 16px */
	}

	&.rounded-r-xl {
		border-top-right-radius: 1.6rem; /* 16px */
		border-bottom-right-radius: 1.6rem; /* 16px */
	}
	&.rounded-xl {
		border-radius: 1.6rem; /* 16px */
	}

	&.borderb-b {
		border-bottom-width: 0.1rem solid rgba(102, 112, 133, 1);
	}
	&.border-2 {
		border: 0.2rem solid rgba(102, 112, 133, 1);
	}
	&.border-2-transparent {
		border: 0.2rem solid transparent;
	}
	&.border-b-2 {
		border-bottom-width: 0.2rem;
	}
	&.border-t-2 {
		border-top-width: 0.2rem;
	}
	&.box-border {
		box-sizing: border-box;
	}
	&.box-content {
		box-sizing: content-box;
	}
	&.bg-gray-50 {
		background-color: $bg-input-disable;
	}
	&.text-gray-500 {
		color: $btn-text-gray-default;
	}

	&.border-gray-500 {
		border-color: rgba(102, 112, 133, 1);
	}

	&.border-app-primary {
		border-color: $btn-secondary-default;
	}

	&.bg-app-primary {
		background-color: $btn-primary-default;
	}
	&.bg-app-primary-50 {
		background-color: $bg-primary-transparent;
	}
	&.text-app-primary {
		color: $navy-blue-500;
	}
	&.text-blue-primary {
		color: $blue-primary;
	}

	&.bg-app-secondary {
		background-color: $secondary-500;
	}
	&.bg-app-secondary-50 {
		background-color: $secondary-50;
	}
	&.text-app-secondary {
		color: $secondary-500;
	}

	&.bg-app-success {
		background-color: $success-500;
	}
	&.bg-app-success-50 {
		background-color: $success-50;
	}
	&.text-app-success {
		color: $success-500;
	}

	&.bg-app-error {
		background-color: $error-500;
	}
	&.bg-app-error-50 {
		background-color: $error-50;
	}
	&.text-app-error {
		color: $error-500;
	}

	&.text-black {
		color: $text-black;
	}
	&.text-app-primary {
		color: $btn-primary-default;
	}
	&.bg-orange-500 {
		background-color: $btn-secondary-default;
	}

	&.max-h-40 {
		max-height: 30vh;
	}
	&.h-0 {
		height: 0;
	}
	&.rotate-180 {
		transform: rotate(180deg);
	}
	&.rotate-0 {
		transform: rotate(0deg);
	}

	&.h-screen {
		height: 100vh;
	}
	&.overflow-auto {
		overflow: auto;
	}
	&.overflow-hidden {
		overflow: hidden;
	}

	&.opacity-50 {
		opacity: 0.5;
	}

	&.cursor-pointer {
		cursor: pointer;
	}
	&.cursor-not-allowed {
		cursor: not-allowed;
	}

	&.z-10 {
		z-index: 10;
	}
	&.z-20 {
		z-index: 20;
	}
	&.z-30 {
		z-index: 30;
	}
	&.z-40 {
		z-index: 40;
	}
	&.z-50 {
		z-index: 50;
	}
}

.react-calendar {
	width: 350px;
	max-width: 100%;
	background: white;
	border: 1px solid #a0a096;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
}
.react-calendar--doubleView {
	width: 700px;
}

.react-calendar__month-view__days {
	display: flex;
	flex-wrap: wrap;
	row-gap: 0.5rem;
}
.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}
.react-calendar button:enabled:hover {
	cursor: pointer;
}
.react-calendar__navigation {
	height: 44px;
	margin-bottom: 1em;
}
.react-calendar__navigation button {
	min-width: 44px;
	background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	// background-color: #e6e6e6;
	background-color: $bg-primary-transparent;
	border-radius: 0.2rem;
}
.react-calendar__navigation button[disabled] {
	// background-color: #f0f0f0;
	background-color: transparent;
	color: rgba(152, 162, 179, 0.4);
	cursor: not-allowed;
}
.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
	color: rgba(60, 60, 67, 0.6);
	font-weight: 400;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
	border-bottom: none;
	text-decoration: none;
	text-transform: capitalize;
	font-size: 1.3rem;
}

.react-calendar__month-view__weekNumbers {
	font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
	color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}
.react-calendar__tile {
	max-width: 100%;
	text-align: center;
	padding: 0.95em 0.5em;
	border-radius: 100%;
	transition: all;
	animation-duration: 300ms;
}

// hover
.react-calendar__tile:disabled {
	background-color: transparent;
	color: rgba(152, 162, 179, 0.4);
	cursor: not-allowed;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: $bg-primary-transparent;
	color: $btn-primary-default;
}
.react-calendar__tile--now {
	background: transparent;
	color: $btn-primary-default;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: $bg-primary-transparent;
	color: $btn-primary-default;
}
.react-calendar__tile--hasActive {
	background: $btn-primary-default;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: $btn-primary-default;
	color: #fff;
}
.react-calendar__tile--active {
	background: $btn-primary-default;
	color: #fff;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: $btn-primary-default;
	color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
	// background-color: #e6e6e6;
	background-color: $bg-primary-transparent;
}

// Class Utility | MARGIN
.m-0 {
	margin: 0;
}
.my-0 {
	margin-block: 0;
}
.mx-0 {
	margin-inline: 0;
}
.mt-0 {
	margin-top: 0;
}
.mr-0 {
	margin-right: 0;
}
.mb-0 {
	margin-bottom: 0;
}
.ml-0 {
	margin-left: 0;
}
.mr-1 {
	margin-right: 1rem;
}
.mr-2 {
	margin-right: 2rem;
}
.mr-3 {
	margin-right: 3rem;
}
.mb-1_p_6 {
	margin-bottom: 1.6rem;
}
.mb-2_p_4 {
	margin-bottom: 2.4rem;
}
.mt-2_p_4 {
	margin-top: 2.4rem;
}
.fs-14 {
	font-size: 1.4rem;
}
.fs-16 {
	font-size: 1.6rem;
}
