@import "../../../colorvariable.scss";

// J-ddComp means Job-dropdownComponent
.J-ddComp {
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: relative;
    width: max-content;

    &-container {
        padding: .8rem 1.2rem;
        border: 2px solid $bg-White;
        background-color: $btn-primary-default;
        color: $bg-White;
        border-radius: 3.8rem;
        font-size: 1.4rem;
        font-weight: 400;
        transition: all .1s ease-in;
        cursor: pointer;

        &:hover {
            background-color: $btn-outline-text-disable;
        }

        &:active {
            background-color: $bg-White;
            border: 2px solid $btn-primary-default;
            color: $btn-primary-default;

            .J-ddComp-down {
                filter: brightness(0) saturate(100%) invert(14%) sepia(98%) saturate(2685%) hue-rotate(215deg) brightness(102%) contrast(91%);
            }
        }
    }

    &-down {
        width: 2rem;
        margin-left: 1rem;
        padding-bottom: .3rem;
        filter: brightness(0) saturate(100%) invert(100%);
    }

    &-menu-cont {
        position: absolute;
        top: 4.5rem;
        left: 0;
        min-width: 100%;
        width: max-content;
        z-index: 100;
    }

    &-menu {
        border: 1px solid $border-input;
        border-bottom: .05rem solid $border-bottom-dropdown;
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
        padding: 1rem 1.6rem 1rem 1.6rem;
        background-color: $bg-White;
        // width: max-content;
        // max-width: 100%;
        width: 100%;

        & .menuDD {
            display: flex;
            align-items: center;
            padding: .8rem 0rem;
            gap: 1.2rem;

            & label {
                // margin-right: 2rem;
                font-size: 1.6rem;
                font-weight: 400;
            }

            & input {
                margin-left: auto;
            }
        }
    }

    &-apply {
        width: 100%;
        border: 1px solid $border-input;
        border-top: .05rem solid $border-bottom-dropdown;
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        padding: 1.2rem 1.6rem;
        background-color: white;

        & button {
            background-color: $btn-primary-default;
            font-size: 1.4rem;
            font-weight: 600;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: .8rem;
            border: 1px solid $btn-primary-default;
            color: $bg-White;
            padding: .8rem;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}