@import '../../../colorvariable.scss';

.interview-schedule {
  & .heading {
    color: $text-black;
  }

  //   Tab Navigation
  & .tab-navigation {
    position: relative;
    display: flex;
    gap: 2rem;
    padding: 0;
    margin-left: 0;
    list-style-type: none;
    margin-top: 4rem;
    border-bottom: 0.1rem solid $color-placeholder;

    & .tab-navigation__item {
      color: $btn-text-gray-default;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      padding-bottom: 1.4rem;
      text-transform: capitalize;
      border-bottom: 0.2rem solid transparent;

      &.active {
        z-index: 2;
        background-color: #fff;
        font-weight: 700;
        border-bottom: 0.2rem solid $btn-primary-default;
        color: $btn-primary-default;
      }
    }

    & .tab-navigation__item:hover {
      color: $btn-primary-default;
    }
  }

  //   Content Data Null

  & .content-null {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .description {
      text-align: center;

      h4 {
        font-size: 16px;
        line-height: 140%;
        font-style: normal;
        font-weight: 600;
        text-align: center;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */

        text-align: center;

        /* Black */

        color: #1d2939;
      }
    }
  }
}
