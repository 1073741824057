@import "./../../colorvariable.scss";
@import "./../../mixin.scss";

.vComp {
	width: 100%;
	height: 100vh;
	padding: 13rem 11.2rem 0rem 11.2rem;
	background: linear-gradient(to right, $btn-primary-default 45%, white 0%);

	@media only screen and (max-width: 450px) {
		padding: 3.2rem;
	}

	&-main {
		height: 100%;
		display: flex;

		@media only screen and (max-width: 450px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 5rem;
		}
	}

	&-left {
		&-content {
			width: 40rem;
			color: $bg-White;
			font-weight: 700;
			line-height: 6.8rem;
			@include display-lg;
		}

		&-progress {
			& h4 {
				padding-left: 1.3rem;
				color: $bg-White;
				font-weight: 600;
				@include text-md;
			}

			& .nonActive {
				font-weight: 200;
			}
		}
	}

	&-right {
		padding: 1rem 0rem;

		@media only screen and (max-width: 450px) {
			padding: 1rem;
		}

		&-content {
			width: 100%;

			& .title {
				display: flex;
				align-items: center;
				color: $text-black;

				& h1 {
					font-weight: 600;
					@include display-sm;
				}

				& p {
					margin: 0;
					margin-left: auto;
					font-weight: 400;
					@include text-sm;
				}
			}

			.uploadDefault {
				background-color: $bg-input-disable;
				border: 1px dashed $border-input;
			}

			.uploadSuccess {
				background-color: $success-background;
				border: 1px dashed $success-color;
			}

			& .upload {
				border-radius: 0.8rem;
				margin: 3.2rem 0rem 5.2rem 0rem;
				padding: 2.4rem;
				display: flex;
				align-items: center;
				justify-content: center;

				&-failed {
					background-color: $error-color;
				}

				&-img {
					display: flex;
					justify-content: center;
				}

				&-title {
					color: $btn-text-gray-default;

					& h3 {
						font-weight: 400;
						padding: 1.6rem 0rem;
						@include text-sm;
					}

					& span {
						font-weight: 600;
						margin-left: 0.8rem;
						@include text-sm;
					}

					& .uploadLink {
						color: $btn-primary-default;
					}

					& .uploadText {
						color: $text-black;
					}

					& h4 {
						font-weight: 600;
						@include text-sm;
					}
				}
			}

			& .vCompSkip {
				padding: 1.2rem 5.2rem;
			}

			& .vCompNext {
				padding: 1.2rem 3.3rem;
			}
		}
	}
}
