@import "../../../../colorvariable.scss";
@import "../../../../variable.scss";

.job-ads-listing__filtering-wrapper {
	margin-top: 3.2rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@media only screen and (min-width: 1276px) {
		flex-direction: row;
		gap: 4rem;
	}

	&__search-input {
		max-width: 450px;

		@media only screen and (max-width: 1276px) {
			margin-bottom: 2rem;
		}
	}

	&__dropdown {
		display: flex;
		align-items: center;
		gap: 1.6rem;

		&-text {
			color: $gray-500;
			margin-bottom: 0;
			text-wrap: nowrap;
		}

		&-item {
			width: 22rem;
		}
	}
}
