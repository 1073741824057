@import "./../../colorvariable.scss";
@import "../../mixin.scss";
@import "../../variables.scss";

.header-nav {
	width: 100%;
	height: 7rem;
	background-color: $bg-White;
	border-bottom: 1px solid #f2f4f7;
	padding: 1.6rem 2rem;
	// overflow: auto;
	display: flex;
}

.hn {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: stretch;

	h5 {
		margin-bottom: 0;
	}

	&-menu-cont {
		display: flex;
		align-items: center;
		align-self: stretch;
		gap: 3.2rem;
		height: calc(100% + 3.2rem);
		margin-top: -1.6rem;
		margin-bottom: -1.6rem;
	}

	&-menu-item {
		cursor: pointer;
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		color: $btn-text-gray-default;
		text-decoration: none;

		& h5 {
			flex-grow: 1;
			transition: ease 100ms;
			@include flex-all-center;
		}

		.bottom-accent {
			background-color: $btn-primary-disable;
			height: 0.4rem;
			width: 100%;
			border-radius: 0.4rem 0.4rem 0 0;
			transition: ease 100ms;
			opacity: 0;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&:hover .bottom-accent,
		&.active .bottom-accent {
			opacity: 1;
		}

		&.active h5 {
			color: $btn-primary-default;
		}
	}

	&-left,
	&-right {
		display: flex;
		align-items: center;

		& h5 {
			@include text-md;
			font-weight: 600;
			color: $btn-text-gray-default;
			padding: 0rem 0.8rem;
		}
	}

	&-left {
		&-img {
			margin: 1.4rem;
			margin-right: 5.4rem;
			height: 4.7rem;
			width: 8vh;
			@media only screen and (max-width: $lg) {
				width: 7rem;
			}
		}
	}

	&-right {
		margin-left: auto;
		gap: 12px;
		.item {
			padding: 8px !important;
		}
	}

	&-border {
		border-right: 2px solid #e6edf8;
		margin-left: 1.2rem;
		height: 6vh;
	}

	&-language {
		border-radius: 50%;
		border: 2px solid #dfebff;
	}

	&-currency-label {
		width: 4rem;
		text-align: center;
	}

	&-profile-label {
		text-align: center;
		white-space: nowrap;
	}
}

.hn-dropdown {
	background-color: white;
	border-radius: 0.8rem;
	padding: 0.8rem 0;
	box-shadow: -2px 0px 10px 0px #056cf233;
	max-height: max-content;
	overflow: auto;
	min-width: 240px;

	.hn-dropdown-item {
		display: flex;
		align-items: center;
		padding: 0.8rem 1.6rem;
		gap: 1.2rem;
		color: $text-black;
		font-weight: 400;
		@include text-sm;
		border: none;
		outline: none;
		background-color: white;
		transition: 100ms;
		border-radius: 0.8rem;
		width: 100%;
		text-decoration: none;

		&.active,
		&:hover {
			background-color: $bg-container;
		}

		&.active {
			cursor: auto;
		}

		&-label {
			max-width: 10rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.mxw-auto {
				max-width: none;
			}
		}

		&-icon {
			margin-right: 0.8rem;
		}

		&.hn-exit-btn {
			margin-top: 0.8rem;
			color: $btn-error-hover;
			background-color: $btn-error-50;
			border-radius: 0;

			&:hover {
				background: $error-100;
			}
		}
	}

	.hn-border-top {
		border-top: 1px solid $border-input;
		margin-bottom: 0.8rem;
	}

	&.hn-lang {
		.hn-dropdown-item-label {
			max-width: max-content;
		}
	}
}

.pad-32 {
	padding: 0rem 3.2rem !important;
}
