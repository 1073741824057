.checkbox-wrapper {
    display: inline-block;
}

.checkbox-label-input {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-bottom: 0px;
    vertical-align: top;
}

.checkbox-label-input:hover .checkmark {
    border: 1px solid #193560;
}

.checkbox-label-input:disabled {
    pointer-events: none;
}

.checkbox-input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-input:active ~ .checkmark {
    background-color: #fff6db;
}

.checkbox-input:disabled ~ .checkmark {
    border: 2px solid #e0e0e0;
    cursor: default;
}

.checkbox-label {
    position: relative;
    bottom: 1px;
    margin-bottom: 0px;
    cursor: pointer;
    width: calc(100% - 20px);
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #979797;
    border-radius: 4px;
    transition: background-color 0.1s ease-out, border 0.1s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.checkmark-checklist::before {
    content: '';
    position: absolute;
    left: 6px;
    top: 1px;
    width: 6px;
    height: 13px;
    border: solid #ffffff;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
}

.checkmark-line::before {
    content: '';
    display: block;
    font-weight: bold;
    color: #ffffff;

    left: 8px;
    top: 3px;
    background: #ffffff;
    height: 11px;
    border: solid #ffffff;
    border-width: 1px 1px 1px 1px;
    transform: rotate(90deg);
}

.checkbox-input:checked ~ .checkmark-checklist {
    background-color: #193560;
    border: 1px solid #193560;
    color: #ffffff;
}

.checkbox-input:disabled:checked ~ .checkmark-checklist {
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
}

.checkbox-input:checked ~ .checkmark-line {
    border: 2px solid #193560;
    color: #193560;
}
.checkbox-input:checked ~ .checkmark-line::before {
    color: #193560 !important;
    background: #193560;
    border: 1px solid #193560;
}

.checkbox-input:disabled:checked ~ .checkmark-line {
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
    color: #979797;
}

.label-position {
    display: flex;
    gap: 10px;
    &__left {
        width: -webkit-fill-available;
        flex-direction: row-reverse;
    }
    &__right {
        flex-direction: row;
    }
}