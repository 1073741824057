@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

// J-btnComp means Job-buttonComponent
.J-btnComp {
	// width: 100%;
	// height: max-content;

	button {
		font-weight: 600;
		border-radius: 0.8rem;
		transition: all 0.1s ease-in;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-primary {
		color: $bg-White;
		background: $btn-primary-default;
		border: 1px solid $btn-primary-default;

		&:hover {
			background: $btn-primary-hover;
			border: 1px solid $btn-primary-hover;
		}

		&:active {
			box-shadow: 0px 0px 0px 4px $btn-outline-hover, 0px 1px 2px rgba(16, 24, 40, 0.05);
		}

		&-disable,
		&-disable:hover,
		&-disable:active {
			background: $btn-primary-disable;
			border: 1px solid $btn-primary-disable;
			cursor: not-allowed;
		}

		&.xs {
			padding: 0.4rem 1.1rem;
			@include text-xs;
		}

		&.sm {
			padding: 1.2rem 1.6rem;
			@include text-sm;
		}

		&.md {
			padding: 1.2rem 2rem;
			@include text-md;
		}

		&.lg {
			padding: 1.6rem 2.4rem;
			@include text-lg;
		}
	}

	&-secondary {
		color: $bg-White;
		background: $btn-secondary-default;
		border: 1px solid $btn-secondary-default;

		&:hover {
			background: $btn-secondary-hover;
			border: 1px solid $btn-secondary-hover;
		}

		&:active {
			box-shadow: 0px 0px 0px 4px $btn-outline-hover-secondary,
				0px 1px 2px rgba(16, 24, 40, 0.05);
		}

		&-disable,
		&-disable:hover,
		&-disable:active {
			background: $btn-secondary-disable;
			border: 1px solid $btn-secondary-disable;
			cursor: not-allowed;
		}

		&.xs {
			padding: 0.4rem 1.1rem;
			@include text-xs;
		}

		&.sm {
			padding: 1.2rem 1.6rem;
			@include text-sm;
		}

		&.md {
			padding: 1.2rem 2rem;
			@include text-md;
		}

		&.lg {
			padding: 1.6rem 2.4rem;
			@include text-lg;
		}
	}

	&-error {
		color: $bg-White;
		background: $btn-error-default;
		border: 1px solid $btn-error-default;

		&:hover {
			background: $btn-error-hover;
			border: 1px solid $btn-error-hover;
		}

		&:active {
			box-shadow: 0px 0px 0px 4px $btn-error-disable, 0px 1px 2px rgba(16, 24, 40, 0.05);
		}

		&-disable,
		&-disable:hover,
		&-disable:active {
			background: $btn-error-disable;
			border: 1px solid $btn-error-disable;
			cursor: not-allowed;
		}

		&.xs {
			padding: 0.4rem 1.1rem;
			@include text-xs;
		}

		&.sm {
			padding: 1.2rem 1.6rem;
			@include text-sm;
		}

		&.md {
			padding: 1.2rem 2rem;
			@include text-md;
		}

		&.lg {
			padding: 1.6rem 2.4rem;
			@include text-lg;
		}
	}

	&-outline {
		background: $bg-White;
		border: 1px solid $btn-primary-default;
		color: $btn-primary-default;

		&:hover {
			background: $btn-outline-hover;
			border: 1px solid $btn-primary-default;
		}

		&:active {
			background: $bg-White;
			box-shadow: 0px 0px 0px 4px $btn-outline-hover, 0px 1px 2px rgba(16, 24, 40, 0.05);
		}

		&-disable,
		&-disable:hover,
		&-disable:active {
			background: $bg-White;
			border: 1px solid $btn-primary-disable;
			color: $btn-outline-text-disable;
			cursor: not-allowed;
		}

		&.xs {
			padding: 0.4rem 1.1rem;
			@include text-xs;
		}

		&.sm {
			padding: 1.2rem 1.6rem;
			@include text-sm;
		}

		&.md {
			padding: 1.2rem 2rem;
			@include text-md;
		}

		&.lg {
			padding: 1.6rem 2.4rem;
			@include text-lg;
		}
	}

	&-textGray {
		border: inherit;
		background: none;
		color: $btn-text-gray-default;

		&:hover {
			color: $btn-text-gray-hover;
		}

		&-disable,
		&-disable:hover,
		&-disable:active {
			color: $btn-text-gray-disable;
			cursor: not-allowed;
		}

		&.xs {
			padding: 0.4rem 1.1rem;
			@include text-xs;
		}

		&.sm {
			@include text-sm;
			padding: 1.2rem 1.6rem;
		}

		&.md {
			padding: 1.2rem 2rem;
			@include text-md;
		}

		&.lg {
			padding: 1.6rem 2.4rem;
			@include text-lg;
		}
	}

	&-textLink {
		border: inherit;
		background: none;
		color: $btn-primary-default;

		&:hover {
			color: $btn-primary-hover;
			text-decoration: underline;
		}

		&-disable,
		&-disable:hover,
		&-disable:active {
			color: $btn-primary-disable;
			text-decoration: none;
			cursor: not-allowed;
		}

		&.xs {
			padding: 0.4rem 1.1rem;
			@include text-xs;
		}

		&.sm {
			padding: 1.2rem 1.6rem;
			@include text-sm;
		}

		&.md {
			padding: 1.2rem 2rem;
			@include text-md;
		}

		&.lg {
			padding: 1.6rem 2.4rem;
			@include text-lg;
		}
	}

	&-whiteImg {
		filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(17%) hue-rotate(321deg)
			brightness(105%) contrast(107%);
	}

	&-blueImg {
		filter: brightness(0) saturate(100%) invert(13%) sepia(59%) saturate(6075%)
			hue-rotate(215deg) brightness(82%) contrast(90%);
	}
}

.padRight {
	position: absolute;
	right: 0.8rem;

	@media only screen and (max-width: 600px) {
		width: 1.3rem;
		top: 0.65rem;
	}

	@media only screen and (min-width: 600px) {
		width: 1.8rem;
		top: 0.33rem;
	}

	@media only screen and (min-width: 768px) {
		width: 2.2rem;
		top: 0rem;
	}
}

.padLeft {
	position: absolute;
	left: 0.8rem;

	@media only screen and (max-width: 600px) {
		width: 1.3rem;
		top: 0.65rem;
	}

	@media only screen and (min-width: 600px) {
		width: 1.8rem;
		top: 0.33rem;
	}

	@media only screen and (min-width: 768px) {
		width: 2.2rem;
		top: 0rem;
	}
}

// @media only screen and (max-width: 600px) {

//     .J-btnComp-primary,
//     .J-btnComp-secondary,
//     .J-btnComp-error,
//     .J-btnComp-textGray,
//     .J-btnComp-textLink,
//     .J-btnComp-outline {
//         border-radius: .4rem;
//         padding: .4rem 2.6rem;
//         font-size: 1.2rem;
//     }
// }

@media only screen and (min-width: 600px) {
	.J-btnComp-primary,
	.J-btnComp-secondary,
	.J-btnComp-error,
	.J-btnComp-textGray,
	.J-btnComp-textLink,
	.J-btnComp-outline {
		padding: 0.8rem 3.2rem;
		font-size: 1.4rem;
	}
}

@media only screen and (min-width: 768px) {
	.J-btnComp-primary,
	.J-btnComp-secondary,
	.J-btnComp-error,
	.J-btnComp-textGray,
	.J-btnComp-textLink,
	.J-btnComp-outline {
		padding: 1.2rem 3.8rem;
		font-size: 1.4rem;
	}
}

@media only screen and (min-width: 992px) {
	.J-btnComp-primary,
	.J-btnComp-secondary,
	.J-btnComp-error,
	.J-btnComp-textGray,
	.J-btnComp-textLink,
	.J-btnComp-outline {
		padding: 1.2rem 4.2rem;
		font-size: 1.6rem;
	}
}

@media only screen and (min-width: 1200px) {
	.J-btnComp-primary,
	.J-btnComp-secondary,
	.J-btnComp-error,
	.J-btnComp-textGray,
	.J-btnComp-textLink,
	.J-btnComp-outline {
		padding: 1.6rem 5.2rem;
		// font-size: 1.8rem;
	}
}
