@import "../../../../colorvariable.scss";

.editor-wrapper {
	display: flex;
	flex-direction: column;

	.editor-header {
		margin-bottom: 1.6rem;

		p {
			margin-bottom: 0.4rem;
		}

		&--label {
			font-size: 1.6rem;
			font-weight: 600;
		}
		&--desc {
			font-size: 1.4rem;
			font-weight: 400;
		}
	}

	.editor-container {
		&.editorError .tox {
			border: 1px solid $btn-error-default;
		}
		.editor-footer {
			margin-top: 0.8rem;
			font-size: 1.4rem;
			font-weight: 400;
			color: #667085;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}
		.error-text {
			color: $btn-error-default;

			.icon-warning {
				margin-right: 0.5rem;
			}
		}

		.count {
			margin-left: auto;
		}
	}
}
