@import "../../../../mixin.scss";
@import "../../../../colorvariable.scss";

.wrapper-job-listing {
	margin-top: 3.2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow-y: auto;
	height: 100%;
	// @include hide-scrollbar;

	/* ===== Scrollbar CSS ===== */
	/* Firefox */
	scrollbar-width: auto;
	scrollbar-color: $bg-dark-blue $gray-50;

	/* Chrome, Edge, and Safari */
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: $gray-50;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $bg-primary;
		border-radius: 10px;
		border: 3px solid #ffffff;
	}
}
