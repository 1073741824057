.whatsapp-sticky-wrapper {
    position: fixed;
    cursor: pointer;
    bottom: 65px;
    right: 20px;
    z-index: 99;
    &.changeWaPosition {
        bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .whatsapp-sticky-wrapper {
        bottom: 10px !important;
        right: 20px !important;
    }
}

.whatsapp-sticky-btn {
    position: relative;
}

.hover-text {
    z-index: -1;
    display: none;
    position: absolute;
    right: 0;
    background-color: #32d951;
    color: #ffffff;
    padding: 10px;
    width: max-content;
    top: 25%;
    border-radius: 8px;
}

.whatsapp-sticky-btn:hover .hover-text {
    display: block;
    animation: slide-in 0.3s ease forwards;
}

@keyframes slide-in {
    from {
        right: 10%;
    }
    to {
        right: 90%;
    }
}
