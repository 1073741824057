@import "../../colorvariable.scss";
@import "../../mixin.scss";

.jp-Profile {
	width: 100%;
	background-color: $gray-50;
	padding-block: 3.2rem;

	.jp-cont {
		width: 72.6rem;
		padding: 2.4rem;
		border: 1px solid $border-input;
		border-radius: 1.2rem;
		background: $white-color;

		display: flex;
		gap: 2.8rem;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;

		h3 {
			font-size: 2rem;
			font-weight: 600;
			color: $text-black;
		}

		.profile-photo {
			@include flex-all-center;
			justify-content: flex-start;
			gap: 3.2rem;

			img {
				height: 8.8rem;
				width: 8.8rem;
				border-radius: 50rem;
				object-fit: cover;
			}

			.upload,
			.delete {
				@include flex-all-center;
				gap: 0.3rem;
				justify-content: flex-start;
				cursor: pointer;
				font-size: 1.6rem;
			}

			.delete {
				color: $gray-300;

				&:hover {
					color: $text-black;
				}
			}
		}

		.form-row {
			display: flex;
			gap: 2rem;
			align-items: center;
		}

		.pass-field {
			position: relative;

			.change-pass-text {
				color: $btn-secondary-default;
				font-size: 1.4rem;
				font-weight: 500;
				position: absolute;
				right: 0;
				cursor: pointer;
			}
		}
	}
}

.ppic {
	@include flex-all-center;
	gap: 1.6rem;

	.avatar {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2.2rem;

		p {
			width: 15rem;
			text-align: center;
			font-size: 1.4rem;
			font-weight: 400;
		}

		img {
			height: 10rem;
			width: 10rem;
			object-fit: cover;
			border-radius: 50rem;
		}

		.profile {
			position: relative;

			.oval-spinner {
				height: 100%;
				width: 100%;
				border-radius: 50rem;
				background: rgba(255, 255, 255, 0.5);

				display: flex;
				align-items: center;
				justify-content: center;

				position: absolute;
			}
		}
	}

	.btns {
		display: flex;
		flex-direction: column;
		gap: 1.4rem;

		p {
			cursor: pointer;
		}

		.upload,
		.delete {
			@include flex-all-center;
			gap: 0.3rem;
			justify-content: flex-start;
			cursor: pointer;
			font-size: 1.6rem;
		}
	}
}
