.tag {
	padding: 0.4rem 0.8rem;
	display: flex;
	gap: 0.4rem;
	border-radius: 4px;

	p {
		margin-bottom: 0;
	}

	&__tag-value {
		font-size: 1.8rem;
		color: #fff;
		font-weight: 700;
	}

	&__tag-description {
		color: var(--success-green-50, #ecfdf3);
		font-size: 1.4rem;
	}

	&-success {
		background: var(--success-green-500, #12b76a);
	}

	&-info {
		background: #fc9607;
	}

	&-error {
		background: var(--error-error-600, #d92d20);
	}
}
