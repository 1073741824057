@import "./../../colorvariable.scss";
@import "../../mixin.scss";
@import "../../variable.scss";

.sign-up {
	.dropdown-item-result {
		width: 100%;
		padding: 8px 16px;
		transition: 0.1s all;

		&:hover {
			background: var(--Primary-Navy-blue-50, #e9ebf0);
			cursor: pointer;

			.dropdown-item-result-text {
				font-weight: 600;
			}
		}

		&-text {
			font-weight: 400;
		}
	}
	&.suc {
		height: 100%;

		.password-valid-msg {
			color: $error-500;
			font-size: 1.4rem;
		}

		.sign-up &-right,
		.sign-up &-left {
			padding: 4rem 10rem;
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			overflow-x: hidden;
		}

		&-left {
			background-color: #f2f4f7;
			margin: auto 0;
			height: 100%;

			.onboarding {
				&-carousel {
					height: 55rem;
					width: 100%;
					position: relative;

					.swiper-pagination {
						margin-top: 3rem !important;
						width: 100px !important;
					}

					.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
						transform: scale(1);
					}

					.swiper-pagination-bullet-active-main {
						background: #c2c3c6;
						width: 4rem;
						border-radius: 50rem;
					}
				}

				&-slide {
					img {
						margin-bottom: 5rem;
						height: 30rem;
						width: 30rem;
						object-fit: contain;
					}

					h5 {
						@include display-sm;
					}

					p {
						margin-top: 1rem;
						@include text-lg;
					}
				}
			}
		}

		&-right {
			overflow: auto;
			display: flex;
			height: 100%;
			justify-content: center;
			align-items: center;

			h2 {
				// padding-top: 8rem;
				text-align: center;
				@include display-md;
			}

			.suc-form {
				width: 35vw;
				display: flex;
				flex-direction: column;
				gap: 2rem;
				margin-bottom: 1rem;

				#password {
					// padding-left: 1rem;
					padding-right: 4rem !important;
				}

				.J-inputComp-input {
					// padding-inline: 1rem !important;
				}

				input.J-inputComp-input[name="phone"] {
					padding-inline: 8rem 1.2rem !important;
				}

				.isRequired::after {
					content: "*";
					color: $btn-error-hover;
				}

				.field-error {
					border-color: $btn-error-default;
				}
			}
		}
	}
}
.phone-input-wrapper {
	border: 1px solid #ccdaef;
	padding: 1.2rem;
	border-radius: 0.8rem;

	& .PhoneInputInput {
		border: none;

		&:focus {
			outline: none;
		}
	}
}
