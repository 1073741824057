.screen-questions {
    &__title {
        line-height: 140%;
        margin-bottom: 8px !important;
        display: flex;
        justify-content: space-between;
        color: var(--Black, #1D2939);
        & span:first-child {
            font-size: 1.8rem;
            font-weight: 700;
        }
        & span:last-child {
            font-size: 1.4rem;
            font-weight: 400;
        }
    }
    &__items {
        display: flex;
        gap: 8px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #D0D5DD;
        &:last-child {
            border-bottom: none;
        }
    }
    &__icon-wrapper {
        img {
            width: 16px;
            
        }
    }
    &__question-wrapper {
        font-size: 16px;
        line-height: 140%;
    }
    &__question {
        color: var(--Black, #1D2939);
        font-weight: 500;
    }
    &__answer {
        color: var(--gray-gray-500, #667085);
        font-weight: 400;
    }
}






