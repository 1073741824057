@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

// J-cardComp means Job List Card Component
.J-listCardComp {
    width: 100%;
    border-radius: 0.8rem;
    padding: 1.6rem;
    position: relative;
    border: 1px solid $border-input;
    background-color: white;
    display: flex;
    cursor: pointer;
    color: $btn-text-gray-default;
    font-size: 1.4rem;
    gap: 1.2rem;
    transition: 100ms;

    &.active {
        border-color: $btn-primary-default;
        box-shadow: 0 0.7rem 1.3rem 0 rgba(0, 0, 0, 0.11);
        outline: 1px solid $btn-primary-default;

        & .J-listCardComp-hotJob {
            top: -2px;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        padding: 0.2rem 0;

        &.smaller {
            font-size: 1.2rem;
        }
    }

    &-hotJob {
        position: absolute;
        right: 0.8rem;
        top: -1px;
    }

    &-data {
        flex-grow: 1;
    }

    &-icon,
    &-icon img {
        width: 5.4rem;
        height: 5.4rem;
        object-fit: contain;
        border-radius: 0.8rem;
    }

    &-title {
        margin-right: 6.5rem;
        @include line-clamp-2;
        max-height: 4.8rem;
        font-weight: 600;
        color: $text-black;
    }
}