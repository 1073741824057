@import "../../../../colorvariable.scss";

.job-ads-list {
	&__card-verify-company {
		margin-bottom: 1.6rem;
		padding: 1.6rem;
		border-radius: 0.8rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&.secondary {
			background-color: $secondary-orange-50;
			border: 1px solid $secondary-400;
		}

		&.primary {
			background-color: $primary-50;
			border: 1px solid $primary-400;
		}

		&.error {
			background-color: $error-50;
			border: 1px solid $error-400;
		}

		&__left {
			display: flex;
			// align-items: center;
			gap: 1.2rem;

			.wrapper-icon {
				padding: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 32px;
				height: 32px;

				&.secondary {
					background-color: $secondary-100;
				}

				&.primary {
					background-color: $primary-100;
				}

				&.error {
					background-color: $error-100;
				}

				.icon {
					&.error {
						color: $error-600;
					}

					&.primary {
						color: $primary-600;
					}

					&.secondary {
						color: $secondary-600;
					}
				}
			}
		}

		&__text {
			&.secondary {
				color: $secondary-800;
			}

			&.error {
				color: $error-800;
			}

			&.primary {
				color: $primary-800;
			}
		}
	}
}
