@import '../../../../colorvariable.scss';
.tooltip .tooltip-inner {
  width: fit-content;
  white-space: normal;
  opacity: 1;
}
.tooltip-inner {
  background-color: $bg-primary-transparent;
  color: $btn-primary-default;
  margin: 0;
  padding: 0.8rem;
  font-weight: 500;
  // max-width: 50%;
  font-size: 1.4rem;
  opacity: 1;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: #2f4fff !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: #2f4fff !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #2f4fff !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #2f4fff !important;
}
