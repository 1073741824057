.input-range {
	margin: 0 auto;
	width: 97%;
}

.input-range__label {
	display: none;
}

.input-range__slider {
	background: #193560;
	outline: 3px solid #193560;
	outline-offset: 4px;
	margin-top: -0.75rem;
}

.input-range__track {
	background: #d0d5dd;
	height: 5px;

	&--active {
		background: #193560;
	}
}

.custom-range-slider {
	position: relative;
	.slider {
		position: relative;
		width: 100%;

		&__track,
		&__range {
			border-radius: 3px;
			height: 5px;
			position: absolute;
		}

		&__track {
			background: #d0d5dd;
			width: 100%;
			z-index: 1;
		}

		&__range {
			background: #193560;
			z-index: 2;
		}

		&__labels {
			display: flex;
			justify-content: space-between;
			padding-top: 2rem;
			z-index: 2;

			p {
				color: #667085;
			}
		}
	}

	.thumb,
	.thumb::-webkit-slider-thumb {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
	}

	.thumb {
		pointer-events: none;
		position: absolute;
		height: 0;
		width: 100%;
		outline: none;

		&--z-index-3 {
			z-index: 3;
		}

		&--z-index-4 {
			z-index: 4;
		}

		&--z-index-5 {
			z-index: 5;
		}
	}

	.thumb::-webkit-slider-thumb {
		position: relative;
		background-color: #193560;
		outline: 3px solid #193560;
		outline-offset: 4px;
		border-radius: 50%;
		height: 18px;
		width: 18px;
		margin-top: 4px;
		pointer-events: all;
		cursor: pointer;
	}
}
