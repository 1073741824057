@import "../../../colorvariable.scss";

.header-dashboard {
	width: 100%;
	position: absolute;
	z-index: 3;
	background-color: #fff;
	box-sizing: border-box;
	padding: 0 3.2rem; /* 16px 32px */
	gap: 1.6rem; /* 16px */

	&__hn-dropdown {
		width: 415px;
	}

	&__dropdown-topic {
		border-bottom: 1px solid $border-input;
		padding: 1.6rem 0;
	}

	&__navigation-content-wrapper {
		display: flex;
		gap: 1.2rem;
		align-items: center;
	}

	&__title-dropdown-topic {
		padding: 0 1.6rem 1.6rem 1.6rem;
	}

	&.is-sticky-header {
		position: sticky;
		top: 0;
		left: 0;
	}

	&.heading-text {
		color: $home-title-color;
		font-style: normal;
		font-weight: 500;
		font-size: 3rem; /* 30px */
		line-height: 3.8rem; /* 38px */
	}

	.company-description__company-name {
		margin-bottom: 0;
		font-weight: 600;
		color: #1d2939;
	}
	.company-description__company-owner {
		margin-bottom: 0;
		color: $gray-500;
		font-size: 14px;
		max-width: 280px;
	}

	&__profile-details {
		gap: 0.8rem;
		cursor: pointer;
		&_text {
			font-size: 1.4rem;
			color: $gray-500;
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 960px) {
	.header-margin {
		margin-left: 10rem;
	}
}

@media screen and (min-width: 960px) {
	.header-margin {
		margin-left: 26rem;

		&.collapse-sidebar {
			margin-left: 10.1rem;
		}
	}
}

.header-margin {
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
