@import '../../../../colorvariable.scss';

.inputType {
	background-color: #fff;
	border: 0.1rem solid $border-input;
	padding: 1.2rem;
	border-radius: 0.8rem;
	width: 100%;
	font-size: 1.6rem;
	&::placeholder {
		color: $color-placeholder;
	}

	&:disabled {
		border: 1px solid var(--gray-gray-200, #eaecf0);
		background: var(--gray-gray-50, #f9fafb);
		color: var(--gray-gray-300, #d0d5dd);

		&:hover {
			cursor: not-allowed;
		}
	}

	&:focus {
		box-shadow: 0px 3px 0px $btn-outline-hover, 0px 1px 2px rgba(16, 24, 40, 0.05);
		border-color: $btn-primary-default;
		outline: 0;
	}

	&.withIcon {
		padding-left: 4.5rem;
	}

	&.isError {
		&:focus {
			border: 0.1rem solid $btn-error-default;
		}
		border: 0.1rem solid $btn-error-default;
	}
}
