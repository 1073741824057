@import 'colorvariable.scss';

.modal-collaborate__modal-body {
	.card-repost-method {
		border: 1px solid $border-input;
		border-radius: 1.2rem;
		padding: 1.6rem 2rem;

		&.selected {
			border: 1.5px solid $navy-blue-500;
		}
	}
}

.modal-job-ad-delete {
	gap: 1rem;
	padding: 2.4rem 3.2rem;
	.modal-deactivate__modal-body__button-groups {
		margin-top: 0;
	}
}
