.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    border: 4px solid #193560;
    border-left: 4px solid #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner-small .spinner {
    width: 20px;
    height: 20px;
    border-width: 2px;
}

.loading-spinner-large .spinner {
    width: 60px;
    height: 60px;
    border-width: 6px;
}

.loading-spinner-medium .spinner {
    width: 40px;
    height: 40px;
    border-width: 4px;
}
