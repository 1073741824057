.tooltip-info-container {
    position: relative;
    display: inline-block;
}

.tooltip-info-trigger {
    position: relative;
}

.tooltip-info {
    visibility: visible;
    width: 30rem;
    background-color: #1D2939;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s;
    margin-top: 12px;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hide-tooltip-info {
    visibility: hidden !important;
    opacity: 0 !important;
}

.tooltip-info::after {
    content: "";
    position: absolute;
    top: 0;
    left: 1.5rem;
    margin-top: -10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
}

.tooltip-info-wrapper {
    display: flex;
    gap: 0.69rem;
    span {
        min-width: 7rem;
        text-align: left;
    }
}

.download-details-btn {
    border: 1px solid #667085;
    border-radius: 4px;
    font-size: 0.75em;
    // margin-top: 35px;
    cursor: pointer;
    & > span {
        color: #475467;
        padding: 8px;
    }
    span {
        font-size: 1.3rem;
        font-style: normal;
        line-height: 140%;
    }
}
.download-file-btn {
    background-color: #193560;
    padding: 0px 8px;
    color: #ffffff;
    border-radius: 4px;
    font-size: 0.75em;
    margin-left: 8px;
    cursor: pointer;
    padding-top: 1px;
    span {
        font-size: 1.3rem;
        font-style: normal;
    }
}
