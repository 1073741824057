@import "./../../colorvariable.scss";

// Temporary height
.h-70 {
    height: 100vh;
}

.bDummy {
    background-color: $btn-primary-default;
    margin: 0;
    padding: 0;

    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .8rem;
    }

    &-input {
        margin: 1rem;
    }
}

.testing {
    border: 2px solid orange !important;
}