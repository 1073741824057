.tooltip-container {
    position: relative;
    display: inline-block;
    &.tooltip-hover:hover .tooltip {
        opacity: 1 !important;
        visibility: visible !important;
    }
}

.tooltip-trigger {
    position: relative;
}

.tooltip {
    visibility: hidden;
    opacity: 0;
    max-width: 25rem;
    width: max-content;
    background-color: #1D2939;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    transition: opacity 0.3s;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &::after {
        content: "";
        position: absolute;
        margin-top: -10px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #333 transparent;
    }
    &-triangle {
        margin-top: 6px !important;
        &::after {
            display: none !important;
        }
    }
}


// .hide-tooltip {
//     visibility: hidden !important;
//     opacity: 0 !important;
// }

.tooltip-wrapper {
    display: flex;
    gap: 0.69rem;
    text-align: left;
    font-size: 1.2rem;
    & span {
        min-width: 5rem;
        // font-size: 1rem !important;
    }
}

.tooltip-potition__bottom {
    top: 100%;
    right: -50%;
    margin-top: 12px;
}

.tooltip-potition__bottom::after {
    top: 0;
    // left: 1.5rem;
}
.tooltip-potition__bottom-left {
    top: 100%;
    left: 0;
    margin-top: 12px;
}

.tooltip-potition__bottom-left::after {
    top: 0;
    left: 1.5rem;
}

.tooltip-potition__bottom-right {
    top: 100%;
    right: 0;
    margin-top: 12px;
}

.tooltip-potition__bottom-right::after {
    top: 0;
    right: 1rem;
}

.tooltip-potition__left {
    top: 100%;
    left: 0;
}

.tooltip-potition__left::after {
    top: 0;
    left: 1rem;
}

// TECHDEBT: need to add this style for another position
.tooltip-potition__right {}
.tooltip-potition__right::after {}
.tooltip-potition__top-left {}
.tooltip-potition__top-left::after {}
.tooltip-potition__top-right {}
.tooltip-potition__top-right::after {}