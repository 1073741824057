.checkbox-parent-select {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-parent-select input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkbox-parent-select__checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 24px;
	width: 24px;
	background-color: transparent;
	border: 1px solid #a3aebf;
	border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.checkbox-parent-select:hover input ~ .checkbox-parent-select__checkmark {
	// background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-parent-select input:checked ~ .checkbox-parent-select__checkmark {
	background-color: #193560;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-parent-select__checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-parent-select input:checked ~ .checkbox-parent-select__checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-parent-select .checkbox-parent-select__checkmark:after {
	left: 9px;
	top: 4px;
	width: 6px;
	height: 11px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.card-parent-company {
	border-radius: var(--radius-md, 8px);
	border: 1px solid var(--Stroke, #ccdaef);
	padding: 16px 12px;
	max-width: 450px;
	width: 100%;
	cursor: pointer;

	&:hover {
		background-color: #e9ebf0;
		border: 1px solid #475467;
	}

	&.checked {
		background-color: #e9ebf0;
		border: 1px solid #475467;
	}

	&__icon {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.checkbox-parent-company {
	margin-bottom: 0;
}
