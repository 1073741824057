.modal {
	&-header,
	&-body,
	&-footer {
		padding: 3.2rem;
	}

	&-header {
		border-top-left-radius: 1.6rem;
		border-top-right-radius: 1.6rem;

		h3 {
			font-size: 2.3rem;
			font-weight: 700;
		}
	}

	&-content {
		border-radius: 1.6rem !important;
	}

	&-body {
		.content-wrapper {
			p {
				font-size: 1.6rem;
				font-weight: 600;
			}

			.input-row {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 1.2rem;

				.day-input {
					input {
						width: 13rem;
						padding: 1.2rem !important;
					}
				}

				span {
					flex: 1;
				}
			}
		}
	}

	&-footer {
		.btn-grp {
			width: 100%;
			display: flex;
			gap: 1.6rem;
			justify-content: stretch;
			position: relative;

			.J-btnComp {
				flex: 1;

				button {
					width: 100%;
				}
			}
		}
	}
}
