@import './../../colorvariable.scss';

.main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100vh;

    .active-comp {
        flex: 1;
    }
}
