@import "./../../../colorvariable.scss";

.custom-navigation {
	&.flexBox {
		width: 100%;
		display: flex;
		align-items: center;
		padding-top: 1em;
		padding-bottom: 1em;
		flex-wrap: wrap;
		& .text {
			font-weight: 700;
			margin-bottom: 1rem;
		}
		& .page-num {
			text-align: center;
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			-moz-appearance: textfield; /* Firefox */
		}
		& .pagination {
			border: 1px solid #ccdaef;
			outline: none;
			border-radius: 0.8rem;
			margin-bottom: 0;
		}
		& .page-item {
			& .page-link {
				color: black;
				background-color: white;
				cursor: pointer;
				font-size: 1.4rem;
				padding: 1rem 1.6rem;
				font-weight: 500;
				transition: 100ms;
				border: 1px solid $border-input;
				border-top: 0;
				border-bottom: 0;
				height: 100%;

				&:hover {
					background: $primary-50;
				}
			}

			&.active {
				& .page-link {
					color: $color-pagination-arrow;
					background: $primary-50;
					border-color: var(--bs-pagination-hover-border-color);
					cursor: auto;
					height: 100%;
				}
			}
		}
		&.page-item {
			// border-top: 1px solid $border-input;
		}
		& .page-item:first-of-type {
			border-radius: 0.8rem 0 0 0.8rem;
			overflow: hidden;

			&.disabled {
				cursor: not-allowed;
				color: $btn-text-gray-disable;
				background: $bg-input-disable;
				border-color: #dddddd;
			}

			& .page-link {
				border-radius: 0.8rem 0 0 0.8rem;
				background: var(--themeBlue);
				color: $color-pagination-arrow;
				border-left: none;
				padding: 1rem 1rem;

				&:hover {
					background: $primary-50;
				}
			}
		}
		& .page-item:last-of-type {
			border-radius: 0 0.8rem 0.8rem 0;
			overflow: hidden;

			&.disabled {
				cursor: not-allowed;
				color: $btn-text-gray-disable;
				background: $bg-input-disable;
				border-color: #dddddd;
			}

			& .page-link {
				border-radius: 0 0.8rem 0.8rem 0;
				color: $color-pagination-arrow;
				border-right: none;
				padding: 1rem 1rem;

				&:hover {
					background: $primary-50;
				}
			}
			&.disabled .page-link {
				color: #919191;
				background: $bg-input-disable;
				border-color: #dddddd;
			}
		}
	}
}
