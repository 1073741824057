@import '/src/colorvariable.scss';

.modal-collaborate {
	width: 600px;

	.container-checkbox {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 30px;
		cursor: pointer;
		font-size: 20px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.container-checkbox input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: white;
		border: 1px solid $gray-500;
		border-radius: 3px;
	}

	/* On mouse-over, add a grey background color */
	// .container-checkbox:hover input ~ .checkmark {
	// 	background-color: #ccc;
	// }

	/* When the checkbox is checked, add a blue background */
	.container-checkbox input:checked ~ .checkmark {
		background-color: $navy-blue-500;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container-checkbox input:checked ~ .checkmark:after {
		display: block;
	}

	.container-checkbox input:disabled ~ .checkmark {
		border: 2px solid $gray-200;
		background-color: $gray-100;
	}

	/* Style the checkmark/indicator */
	.container-checkbox .checkmark:after {
		left: 9px;
		top: 4px;
		width: 7px;
		height: 14px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.container-checkbox .checkmark:after {
		left: 9px;
		top: 2px;
		width: 7px;
		height: 14px;
		background-color: solid $gray-300;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&__modal-header {
		padding: 2.6rem;
		&_text {
			font-size: 2rem;
			font-weight: 600;
		}
		&_btn-close {
			cursor: pointer;
		}
	}
	&__modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 3.2rem;

		&__text-head {
			font-size: 14px;
			margin-bottom: 0;
		}

		&__card-staff-wrapper {
			&.scroll {
				overflow-y: scroll;
				max-height: 230px;
			}

			& .empty-staff-state {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 150px;
			}
		}

		&__card-staff-list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			// align-self: flex-start;
			width: 100%;
			border: 1px solid $border-input;
			padding: 0.8rem 1.6rem;
			border-radius: 0.8rem;
			margin-top: 0.8rem;

			&__left {
				display: flex;
				align-items: center;

				&_staff {
					margin-left: 1.2rem;

					&-avatar {
						width: 40px;
						height: 40px;
						border-radius: 40px;
					}

					&-name {
						font-weight: 600;
						margin-bottom: 0;
					}
					&-position {
						font-weight: 400;
						font-size: 1.4rem;
						margin-bottom: 0;
						text-align: start;
					}
				}
			}

			&__right {
				display: flex;
			}
		}

		&__search-input {
			flex: 1;
			width: 100%;
			margin-bottom: 1.6rem;
		}
	}

	&__btn-save {
		flex: 1;
		padding: 1.25rem 1.5rem;
	}

	&__btn-save-btn {
		width: 100%;
	}
}
