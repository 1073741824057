.checkbox-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkbox-container__checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: transparent;
	border: 1px solid #ffffff;
	border-radius: 15px;
	margin-top: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-container__checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-container__checkmark {
	background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-container__checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-container__checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-container__checkmark:after {
	left: 5px;
	top: 2px;
	width: 5px;
	height: 9px;
	border: solid #193560;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
