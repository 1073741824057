@import "/src/colorvariable.scss";
@import "/src/variables.scss";

.candidate-management {
	padding: 25px;
	background-color: #eff0f2;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.white-background-div {
		background-color: white;
		border-radius: 12px;
		padding: 25px;
		height: 100%;
        @media screen and (max-width: $md) {
            & > div:first-child {
				flex-direction: column !important;
				align-items: flex-start !important;
				& > div:first-child {
					width: 100% !important;
					& .formInputType {
						top: -0.5rem;
					}
				}
			}
			& .section-head__employer-assist {
				justify-content: flex-start !important;
				&--wrapper {
					margin-top: 3rem !important;
					align-items: flex-start;
					justify-content: space-between !important;
					width: 100%;
				}
			}
			& .tab-navigation__container {
				display: flex;
				flex-direction: column;
				border-bottom: unset;
				& > div {
					border-bottom: 1px solid #98A2B3;
				}
				& .tab-navigation__wrapper .tab-navigation__items {
					display: flex;
					justify-content: space-around;
				}
				& .tab-navigation__employee-hired-count {
					display: flex;
					margin-top: 1rem;
					justify-content: flex-end;
				}
			}
        }
	}
	.role-div {
		display: flex;
		justify-content: space-between;
		height: 90px;
	}
	.heading {
		margin: 0px;
		font-size: 1.5em;
	}
	.search-bar-div {
		border: 1px solid #ccdaef;
		border-radius: 8px;
		padding: 11px 14px;
		display: flex;
		align-items: center;
		margin-top: 20px;
		width: 404px;
		.search-input {
			border: none;
			outline: none;
			margin-left: 5px;
			width: 100%;
		}
	}
	.filter-btn {
		margin-top: 20px;
		border: 1px solid #193560;
		border-radius: 8px;
		margin-left: 8px;
		padding: 12px 16px;
		cursor: pointer;
		span {
			color: #193560;
			margin-right: 5px;
		}
	}
	.emp-assist-div {
		display: flex;
		align-items: center;
		// margin-left: 16rem;
		span {
			font-weight: 600;
			font-size: 1em;
		}
	}
	.quickmove-btn {
		margin: 0px;
		border-left: 1px solid white;
		background-color: $navy-blue-500;
		color: white;
		padding: 12px 16px;
		border-radius: 8px 0px 0px 8px;
		cursor: pointer;
		font-weight: 400;
	}
	.quickmove-arrow {
		font-size: 25px;
		border-left: 1px solid white;
		background-color: $navy-blue-500;
		color: white;
		border-radius: 0px 8px 8px 0px;
		padding: 12px 15px 12px 18px;
		cursor: pointer;
	}
	.mar-top {
		margin-top: 18px;
	}
	.header-div {
		display: flex;
		border-bottom: 1px solid #98a2b3;
		margin-top: 36px;
		height: 36px;
		.header-item {
			// width: 140px;
			padding: 0px 15px;
			margin-bottom: -2.5px;
			font-weight: 600;
			color: #667085;
			cursor: pointer;
			transition: 0.2s;
			span {
				background-color: #fc9607;
				padding: 0px 8px;
				border-radius: 20px;
				margin-left: 10px;
				color: white;
				font-size: 1.4rem;
			}
			&.selected {
				color: $navy-blue-500;
			}
		}
		.border-bottom-blue {
			width: 120px;
			height: 4px;
			border-radius: 2px;
			background-color: $navy-blue-500;
			position: absolute;
			display: flex;
			align-self: flex-end;
			margin-bottom: -2px;
			transition: 0.6s;
		}
	}
	.mbottom-30 {
		margin-bottom: 30px;
	}
	.mtop-30 {
		margin-top: 30px;
		.empty-state-candidate {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.no-data-div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: calc(100vh - 369px);
			.para1 {
				color: #1d2939;
				font-size: 1em;
				font-weight: 600;
				margin-top: 65px;
			}
			.para2 {
				color: #1d2939;
				font-size: 0.875em;
				font-weight: 400;
				margin-top: 8px;
			}
		}
	}
	.candidates-div {
		display: flex;
		flex-direction: column;
		max-height: calc(100vh - 368px);
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		& .quick-move-status {
			display: flex;
			align-items: center;
			border: 1px solid #D0D5DD;
			border-radius: 8px;
			& > div {
				border-right: 1px solid #D0D5DD;
				padding: 0.35rem 0.55rem;
				&:last-child {
					padding-right: none;
					border-right: none;
				}
			}
		}
		& div:first-child {
			display: flex;
			justify-content: space-between;
		}
		&::-webkit-scrollbar {
			display: none;
		}
		.candidate-checkbox {
			width: 16px;
			height: 16px;
			margin-top: 9px;
		}
		.bulk-options-div {
			margin-bottom: 15px;
			gap: 15px;
			.bulk-option-logo {
				cursor: pointer;
			}
			.vertical-line-lite {
				height: 25px;
				width: 1px;
				border-left: 2px solid #d0d5dd;
			}
			.status-btn {
				border: 1px solid #667085;
				font-size: 0.75em;
				font-weight: 600;
				color: #667085;
				border-radius: 4px;
				padding: 2px 8px;
				cursor: pointer;
			}
		}
		.candidate-outer-div {
			display: flex;
			align-items: flex-start;
			.candidate-div {
				border: 1px solid #ccdaef;
				border-radius: 8px;
				width: calc(100% - 45px);
				margin: 5px 15px;
				padding: 12px;
				cursor: pointer;
				p {
					margin: 0px;
				}
				&.unseen {
					background-color: #e7edf7;
				}
				&.selected {
					border: 2px solid #3e6dbc;
				}
				.candidate-name {
					color: #1d2939;
					font-weight: 600;
					font-size: 1em;
				}
				.candidate-role {
					font-weight: 500;
					color: #1d2939;
					font-size: 0.875em;
				}
				.candidate-company {
					font-weight: 400;
					color: #667085;
					font-size: 0.875em;
				}
				.candidate-date-applied {
					font-weight: 600;
					color: #475467;
					font-size: 0.875em;
					margin-top: 10px;
				}
				.roles-available {
					font-weight: 400;
					color: #1d2939;
					font-size: 0.875em;
					margin-top: 10px;
				}
				.user-action-icon {
					cursor: pointer;
					margin: 0;
					// margin-left: 5px;
				}
			}
		}
	}
	.mar-top-10 {
		margin-top: 10px;
	}
	.candidate-data-div {
		border: 1px solid #d0d5dd;
		border-radius: 12px;
		height: fit-content;
		padding: 0px;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 369px);
		max-height: calc(100vh - 299px);
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		p {
			margin: 0px;
		}
		.candidate-data-upper-div {
			padding: 20px;
			border-bottom: 1px solid #ccdaef;

			@media screen and (max-width: $xl) {
				& > div:first-child {
					flex-direction: column !important;
					& > div:last-child {
						display: flex;
						align-items: flex-start;
						padding: 24px 0;
						gap: 0px;
						flex-direction: column;
					}
				}
			}
		}
		.candidate-name {
			font-size: 1.5em;
			color: #1d2939;
			font-weight: 600;
		}
		.candidate-gmail {
			font-size: 0.875em;
			color: #1d2939;
			font-weight: 400;
		}
		.vertical-line {
			height: 22px;
			width: 1px;
			border-left: 2px solid #d0d5dd;
			margin: 0px 5px;
		}
		.icon-margin {
			margin: 0px 10px;
			cursor: pointer;
		}
		.status-label {
			font-size: 0.875em;
			color: #667085;
			margin-bottom: 8px;
		}
		.comments-div {
			margin-top: 35px;
			span {
				font-size: 0.75em;
				color: #193560;
			}
			.icon {
				margin-right: 5px;
			}
			.comment-item {
				margin: 0px 5px;
				cursor: pointer;
			}
		}
		.candidate-data-lower-div {
			padding: 36px 24px;
			.heading {
				color: #1d2939;
				font-weight: 600;
				font-size: 1.5em;
				&.size-sm {
					font-size: 1.125em;
					font-weight: 700;
				}
			}
			.comment-input {
				outline: none;
				border: 1px solid #ccdaef;
				border-radius: 8px;
				padding: 12px;
				width: calc(100%);
				margin-top: 24px;
			}
			.post {
				padding: 12px;
				background-color: #193560;
				border-radius: 8px;
				margin-left: 8px;
				margin-top: 24px;
			}
			.posted-comments-div {
				margin-top: 24px;
				.posted-comment {
					background-color: #f2f4f7;
					padding: 12px;
					border-radius: 8px;
					margin-bottom: 10px;
					.comment {
						color: #1d2939;
						font-size: 0.875em;
						font-weight: 400;
					}
					.comment-details-div {
						display: flex;
						justify-content: space-between;
						margin-top: 16px;
						.profile-pic {
							margin-right: 8px;
						}
						.comment-owner {
							color: #1d2939;
							font-weight: 600;
							font-size: 0.875em;
						}
						.comment-time {
							color: #667085;
							font-size: 0.75em;
							font-weight: 400;
						}
					}
				}
			}
			.history-data {
				margin-top: 24px;
				.application-history-item {
					display: flex;
					margin-bottom: 10px;
				}
				.date-data-div {
					min-width: 120px;
					.application-date {
						color: #667085;
						font-size: 0.875em;
						font-weight: 600;
					}
					.application-status {
						color: #667085;
						font-size: 0.75em;
						font-weight: 400;
					}
				}
				.applied-role {
					color: applied-role;
					font-size: 1.125em;
					font-weight: 700;
				}
				.applied-company {
					color: #1d2939;
					font-size: 0.875em;
					font-weight: 400;
				}
				.reviewed-by {
					color: #667085;
					font-size: 0.875em;
					font-weight: 400;
				}
			}
			.building-icon {
				margin-right: 18px;
			}
			.experience-div {
				margin-top: 24px;
				display: flex;
				align-items: flex-start;
				p {
					color: #1d2939;
				}
				.company-name {
					font-weight: 700;
					font-size: 1.125em;
				}
				.role {
					font-weight: 400;
					font-size: 0.875em;
				}
				.light-data {
					color: #667085;
					font-size: 0.875em;
					font-weight: 400;
				}
				.description {
					font-weight: 600;
					font-size: 0.875em;
					color: #1d2939;
					margin-top: 16px;
				}
			}
		}
		.no-data-div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: calc(100vh - 369px);
			.para1 {
				color: #1d2939;
				font-size: 1em;
				font-weight: 600;
				margin-top: 65px;
			}
			.para2 {
				color: #1d2939;
				font-size: 0.875em;
				font-weight: 400;
				margin-top: 8px;
			}
		}
	}
	&__details-scroll-height {
		height: 100vh !important;
	}
	&__list-scroll-height {
		height: 100vh !important;
		max-height: calc(100vh - 300px) !important;
	}
}
.status-dropdown-item {
	min-width: 190px;
}
.quickmove-dropdown-item {
	min-width: 212px;
}
.modal-employer-assist {
	padding: 32px 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.modal-employer-heading {
		font-size: 1.5em;
		font-weight: 600;
		color: #1D2939;
		margin-top: 40px;
	}
	ul {
		margin-top: 12px;
		li {
			color: #667085;
		}
	}
	.cancel-btn {
		display: flex;
		justify-content: center;
		border: 1px solid #667085;
		color: #475467;
		border-radius: 8px;
		padding: 12px;
		width: 45%;
		margin-right: 10%;
		font-size: 0.875em;
		margin-top: 40px;
		cursor: pointer;
	}
	.accept-red-btn {
		display: flex;
		justify-content: center;
		background-color: #f04438;
		color: white;
		border-radius: 8px;
		padding: 12px;
		width: 45%;
		font-size: 0.875em;
		margin-top: 40px;
		cursor: pointer;
	}
}
.modal-download {
	.close {
		color: #667085;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.modal-header {
		background-color: #f9fafb;
		padding: 18px 32px;
		span {
			color: #1d2939;
			font-size: 1.25em;
			font-weight: 600;
		}
	}
	.modal-body {
		padding: 18px 32px;
		.option-div {
			border: 2px solid #ccdaef;
			border-radius: 12px;
			padding: 16px 20px;
			margin: 15px 0px;
			cursor: pointer;
			&.selected {
				border: 2px solid #193560;
			}
			.option-heading {
				color: #1d2939;
				font-size: 1.25em;
			}
			.option-desc {
				color: #667085;
				font-size: 0.875em;
				font-weight: 400;
			}
		}
		.form-div {
			.bordered {
				width: 100%;
				border: 1px solid #ccdaef;
				outline: none;
				border-radius: 8px;
				padding: 12px;
				margin-bottom: 25px;
			}
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			input[type="number"] {
				-moz-appearance: textfield;
			}
			.form-control {
				padding: 0px;
				border: none;
				font-size: 12px;
				input {
					font-size: 16px;
				}
				&:focus {
					box-shadow: none;
				}
			}
			.date-div {
				width: 47%;
				&.mar-right {
					margin-right: 6%;
				}
				.date-outer-div {
					display: flex;
					align-items: center;
					width: 100%;
					border: 1px solid #ccdaef;
					outline: none;
					border-radius: 8px;
					padding: 12px;
					margin-bottom: 15px;
				}
			}
		}
		.pdf-viewer-div {
			max-height: 400px;
			overflow-y: scroll;
			display: flex;
			justify-content: center;
			border: 1px solid #00000040;
			&::-webkit-scrollbar {
				width: 10px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				// -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
				-webkit-border-radius: 10px;
				border-radius: 10px;
				background-color: #f2f4f7;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				-webkit-border-radius: 10px;
				border-radius: 10px;
				background: #d0d5dd;
				// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
			}
		}
		.pdf-header {
			background-color: #1d2939cc;
			// top: 20;
			width: 100%;
			color: white;
			font-size: 12px;
			border-radius: 8px 8px 0px 0px;
			padding: 8px 12px;
			cursor: pointer;
		}
		.pdf-foot {
			background-color: #1d2939cc;
			width: 100%;
			color: white;
			border-radius: 0px 0px 8px 8px;
			padding: 8px 12px;
			cursor: pointer;
			font-size: 12px;
		}
		.download-btn {
			border: 1px solid #667085;
			width: fit-content;
			padding: 4px 8px;
			border-radius: 4px;
			margin-top: 20px;
			cursor: pointer;
			span {
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}
	.modal-footer {
		display: flex;
		justify-content: space-between;
		padding: 15px 32px;
		.btn {
			width: 45%;
			border-radius: 8px;
			padding: 12px 0px;
			font-weight: 600;
		}
		.btn-cancel {
			border: 1px solid #193560;
			color: #193560;
		}
		.btn-download {
			background-color: #193560;
			color: white;
			&.disbaled {
				background-color: #90abd8;
			}
		}
	}
}
.modal-forward {
	.close {
		color: #667085;
		// background-color: #193560;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.modal-header {
		background-color: #f9fafb;
		padding: 18px 32px;
		span {
			color: #1d2939;
			font-size: 1.25em;
			font-weight: 600;
		}
	}
	.modal-body {
		padding: 18px 32px;
		.search-bar-div {
			border: 1px solid #ccdaef;
			border-radius: 8px;
			padding: 11px 14px;
			display: flex;
			align-items: center;
			margin-top: 20px;
			// width: 404px;
			.search-input {
				border: none;
				outline: none;
				margin-left: 5px;
				width: 100%;
			}
		}
		.list-heading {
			font-weight: 400;
			color: #1d2939;
			font-size: 0.875em;
			margin: 14px 0px;
		}
		.staff-list {
			max-height: 250px;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
			.staff-member-div {
				border: 1px solid #ccdaef;
				padding: 8px 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 8px;
				margin-bottom: 8px;
				.image-div {
					width: 40px;
					height: 40px;
					border-radius: 20px;
					border: 1px solid #b4c7e5;
				}
				.staff-name {
					color: #1d2939;
					font-weight: 600;
					margin: 0px 0px 1px 10px;
					font-size: 1em;
				}
				.staff-role {
					color: #1d2939;
					font-weight: 400;
					margin: 0px 0px 0px 10px;
					font-size: 0.875em;
				}
				input[type="checkbox"] {
					width: 22px;
					height: 22px;
				}
			}
		}
	}
	.modal-footer {
		display: flex;
		justify-content: space-between;
		padding: 15px 32px;
		.btn-send {
			width: 100%;
			background-color: #193560;
			color: white;
			padding: 12px 0px;
			border-radius: 8px;
			&.disbaled {
				background-color: #90abd8;
			}
		}
	}
}
.modal-filter {
	.background-light-blue {
		padding: 5px;
		background-color: #f2f4f7;
	}
	.modal-footer {
		.btn-apply-filter {
			// width: 100%;
			background-color: #193560;
			color: white;
			padding: 12px 20px;
			border-radius: 8px;
			cursor: pointer;
		}
	}
}
.bottom-floating-notification {
	position: absolute;
	background-color: #1d2939;
	padding: 16px;
	color: #ffffff;
	border-radius: 8px;
	bottom: 45px;
	margin-left: 35px;
	span {
		color: #90abd8;
		margin: 0pc 15px;
		cursor: pointer;
	}
	&.close {
		cursor: pointer;
	}
}

.right-modal {
	right: 0;
	top: 0;
	margin: 0;
	position: absolute;
	max-width: 56.5rem;
	height: 100vh;

	& .header-modal {
		border-radius: 12px;
		background-color: green;
	}
}
.candidate-loader {
	display: flex;
    gap: 12px;
    padding-right: 18px;
	margin-bottom: 12px;
}
