.search-input-wrapper {
	position: relative;
	flex: 1;
	&__icon {
		padding-left: 4px;
		position: absolute;
		box-sizing: border-box;
		top: 45%;
		left: 2px;
		transform: translateY(-50%);
	}

	&__input {
		box-sizing: border-box;
		padding-left: 30px;
		border-radius: 8px;
		border: 1px solid var(--Stroke, #ccdaef);
		background: var(--White, #fff);
		/* Shadow/xs */
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		width: 100%;
		font-size: 1.6rem;
		height: 4.5rem;

		&:focus {
			border: 1px solid #193560;
			border-color: #193560;
			outline: #193560;
		}
	}
}
