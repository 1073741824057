.terms_ofuse {
    * {
        margin: 0;
        padding: 0;
    }
    display: grid;
    gap: 70px;
    // background-color: yellow;
    padding-bottom: 70px;
    &_header {
        background-color: #193560;
        display: flex;
        color: white;
        justify-content: space-between;
        align-items: center;
        & > p {
            font-weight: 600;
            font-size: 48px;
            text-transform: capitalize;
            // background-color: red;
            padding-left: 10rem;
        }
        & > div {
            width: 400px;
            // background-color: red;
            padding-top: 4rem;
        }
    }
    &_content {
        // background-color: red;
        padding: 0 1rem;
        max-width: 1600px;
        width: 100%;
        margin: 0 auto;
        display: grid;
        gap: 2rem;
        & > .item {
            border: 1px solid #CCDAEF;
            border-radius: 6px;
            padding: 2rem;
            display: grid;
            gap: 2rem;
            h3 {
                color: #1D2939;
                font-weight: 600;
                font-size: 36px;
            }
            h5 {
                color: #1D2939;
                font-weight: 600;
                font-size: 16px;
            }
            & > p {
                font-weight: 400;
                font-size: 14px;
                color: #667085;
                text-align: justify;
            }
            .list_of_contents > p {
                color: #1D2939;
                font-weight: 600;
                font-size: 16px;
            }
            .list_of_contents {
                & > .items_wrapper {
                    margin-top: 1rem;
                    // background-color: red;
                    padding-left: 2.5rem;
                    .item {
                        color: #FC9607;
                        font-weight: 500;
                        font-size: 16px;
                        &:nth-child(odd) {
                            justify-self: end;
                        }
                    }
                }
            }
            .item_data {
                display: grid;
                gap: 10px;
                .title {
                    display: grid;
                    grid-template-columns: auto auto;
                    // background-color: red;
                    width: fit-content;
                    gap: 4px 2.5rem;
                    & > p {
                        font-weight: 500;
                        font-size: 14px;
                        color: #667085;
                        text-align: justify;
                    }
                }
                ul.data_content {
                    li {
                        text-align: justify;
                    }
                }
                .data_content {
                    display: grid;
                    gap: 10px;
                    list-style: disc;
                    padding-left: 3.5rem;
                    // background-color: red;
                    text-align: justify;
                    li {
                        color: #475467;
                        font-weight: 400;
                        font-size: 14px;
                        text-align: justify;
                        strong {
                            font-weight: 700;
                        }
                        strong.yellow {
                            color: #FC9607;
                        }
                    }
                    & > p {
                        font-weight: 400;
                        font-size: 14px;
                        color: #667085;
                        text-align: justify;
                    }
                    ul.another_ul {
                        list-style: lower-alpha;
                        padding-left: 16px;
                        display: grid;
                        gap: 4px;
                        li {
                            padding-left: 16px;
                            ul.ul_inside_ul {
                                // background-color: greenyellow;
                                padding-left: 0;
                                list-style: lower-roman;
                            }
                        }
                    }
                }
                .data_content.custom {
                    padding-left: 0;
                    display: grid;
                    margin-top: 1rem;
                    gap: 1.5rem;
                    // background-color: gold;
                    padding-left: 2rem;
                    & > .item_custom {
                        // background-color: lightblue;
                        display: grid;
                        grid-template-columns: auto auto;
                        width: fit-content;
                        gap: 4px 1rem;
                        .alpha {
                            color: #1D2939;
                            font-weight: 700;
                            font-size: 14px;
                        }
                        .alpha_desc {
                            font-weight: 400;
                            font-size: 14px;
                        }
                        ul.alpha_desc {
                            // background-color: gold;
                            padding-left: 2rem;
                        }
                    }
                }
            }
        }
    }
    .hr_line {
        background-color: #D0D5DD;
        height: 1px;
    }
    ul.letter-icon {
        background-color: lightblue;
        list-style: lower-alpha !important;
        font-weight: 800;
    }
    .link {
        color: #FC9607;
        text-decoration: none;
    }
}

@media (max-width: 1200px) {
    .terms_ofuse {
        &_header {
            flex-direction: column;
            justify-content: center;
            gap: 4rem;
            padding: 3rem 1rem;
            & > p {
                padding: 0;
                font-size: 30px;
                text-align: center;
                line-height: 4.5rem;
            }
            & > div {
                max-width: 350px;
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &_content {
            & > .item {
                h3 {
                    font-size: 26px;
                }
            }
        }
    }
}