@import "./../../colorvariable.scss";
@import "../../mixin.scss";
@import "../../variables.scss";

.hc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8rem 11.2rem;
    max-width: 136.6rem;
    gap: 6.4rem;

    .hero-container { 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap-reverse;

        .hero-left-container { 
            flex: 1;
            max-width: 61rem;
        };
        .hero-title-text { 
            font-size: 4.8rem;
            font-weight: 700;
            color: $text-black;
            margin-bottom: 2.4rem;
            letter-spacing: -0.96px;
        };
        .hero-info-text { 
            font-size: 2.4rem;
            font-weight: 400;
            color: $btn-text-gray-hover;
            margin-bottom: 4.8rem;
        };
        .hero-button { 
            width: 14rem;
            height: 4.8rem;
            font-size: 1.6rem !important;
        };
    };
    
    .step { 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        gap: 4rem;
        
        .step-item-container { 
            padding: 4rem 3.2rem;
            max-width: 34.4rem;
            display: flex;
            flex-direction: column;
            flex: 1 1 22rem;
            gap: 1.2rem;
            border-radius: 1.2rem;
            border: 1px solid $border-bottom-dropdown;
            transition: all .2s;

            > * {
                margin-bottom: 0;
            }

            &:hover {
                border: 1px solid $hover-color;
                box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.13);
                -webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.13);
                -moz-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.13);
            }
        };
        .step-number { 
            font-size: 3rem;
            font-weight: 700;
            color: $btn-secondary-hover;
        };
        .step-title { 
            font-size: 2.4rem;
            font-weight: 600;
            color: $text-black;
        };
        .step-desc { 
            font-size: 1.6rem;
            font-weight: 400;
            color: $btn-text-gray-hover;
        };

        @media screen and (max-width: $md) {
            justify-content: center;
        }
    };

    @media screen and (max-width: $sm) {
        padding-top: 0;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}