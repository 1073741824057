@import "../../colorvariable.scss";

.user-form {
	width: 70%;
	margin: 0 auto;
	margin-top: 5rem;

	// .header-title {
	// 	margin-left: 6.75rem;
	// }

	&__container {
		background: #fff;
		border: 1px solid #ccdaef;
		border-radius: 12px;

		margin-top: 1rem;
		margin-bottom: 2rem;
		padding: 2rem;

		.button-groups {
			.button-groups_wrapper {
				width: 144px;
			}

			.button-groups_btn {
				width: 144px;
			}
		}

		h3 {
			margin-bottom: 1rem;
		}

		.J-inputComp label.isRequired::after {
			content: "*";
			color: red;
			margin-left: 0.2rem;
			font-size: 1.6rem;
		}

		.J-inputComp input {
			padding: 1rem;
		}

		.access-mgmt {
			&__toggle {
				display: flex;
				border: 1px solid #667085;
				border-radius: 8px;
				overflow: hidden;

				span {
					padding: 0.5rem 3rem;
					text-align: center;
					font-size: 1.4rem;
					cursor: pointer;

					&.active {
						color: $primary-400;
						background: #e7edf7;
						font-weight: 600;
					}
				}
			}

			&__permission {
				display: flex;
				align-items: flex-start;
				// gap: 1rem;

				.checkbox-access-mgmt {
					margin-top: 0.5rem;
				}

				input {
					transform: scale(1.2);
					accent-color: $bg-primary;
					margin-top: 0.5rem;
				}

				&-description {
					h3 {
						font-size: 1.8rem;
						font-weight: 550;
						margin: 0;
					}

					p {
						// font-size: 1.5rem;
						color: $text-black;
					}

					.description-description {
						margin-bottom: 3rem;
					}

					&.disabled {
						h3 {
							color: $gray-500;
						}

						p {
							color: $gray-400;
						}

						.title-description {
							color: $gray-500;
						}
					}

					&-radios {
						display: flex;
						gap: 5rem;

						input {
							margin-right: 1rem;
						}

						// span {
						// 	color: #667085;
						// }
					}
				}
			}
		}
	}
}
