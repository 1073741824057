@import "../../../../colorvariable.scss";

.checkbox__container-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 30px;
	cursor: pointer;
	font-size: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox__container-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkbox__container-checkbox_checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: white;
	border: 1px solid $gray-500;
	border-radius: 3px;
}

/* On mouse-over, add a grey background color */
// .checkbox__container-checkbox:hover input ~ .checkbox__container-checkbox_checkmark {
// 	background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.checkbox__container-checkbox input:checked ~ .checkbox__container-checkbox_checkmark {
	background-color: $navy-blue-500;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox__container-checkbox_checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox__container-checkbox input:checked ~ .checkbox__container-checkbox_checkmark:after {
	display: block;
}

.checkbox__container-checkbox input:disabled ~ .checkbox__container-checkbox_checkmark {
	border: 2px solid $gray-200;
	background-color: $gray-100;
}

/* Style the checkmark/indicator */
.checkbox__container-checkbox .checkbox__container-checkbox_checkmark:after {
	left: 6px;
	top: 1px;
	width: 7px;
	height: 12px;
	border: 1px solid $gray-300;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.checkbox__container-checkbox .checkbox__container-checkbox_checkmark:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 11px;
	background-color: solid $gray-300;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
