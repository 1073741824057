@import '../../../colorvariable.scss';

.time-slot {
  & .head-section {
    margin-top: 4rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: #1d2939;
    }
  }

  //   Content Data Null
  & .content-null {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;

    img {
      margin-right: -4rem;
    }

    & .description {
      text-align: center;
      margin-top: 4rem;

      h4 {
        font-size: 16px;
        line-height: 140%;
        font-style: normal;
        font-weight: 600;
        text-align: center;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */

        text-align: center;

        /* Black */

        color: #1d2939;
      }
    }
  }
}
