@import '../../../../colorvariable.scss';
@import '../../../../mixin.scss';

.new-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  //===================== Size Condition =====================
  &.xs {
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    gap: 0.4rem;
    @include text-xs;
  }
  &.sm {
    padding: 0.8rem 1.2rem;
    border-radius: 0.8rem;
    gap: 0.4rem;
    @include text-sm;
  }
  &.md {
    padding: 1.2rem 1.6rem;
    border-radius: 0.8rem;
    gap: 0.8rem;
    @include text-md;
  }
  &.lg {
    padding: 1.2rem 2rem;
    border-radius: 0.8rem;
    gap: 0.8rem;
    @include text-lg;
  }
  &.xl {
    padding: 1.6rem 2.4rem;
    border-radius: 0.8rem;
    gap: 0.8rem;
    @include text-xl;
  }
  //===================== Primary =====================
  &.primary {
    background-color: $navy-blue-500;
    color: $white-color;
    border: 0.1rem solid $navy-blue-500;
  }
  &.primary:hover {
    background-color: $primary-600;
  }
  &.primary:focus {
    box-shadow: 0 0 0 0.2rem $primary-shadow;
  }
  //===================== Outline Primary =====================
  &.outline-primary {
    background-color: $white-color;
    color: $navy-blue-500;
    border: 0.1rem solid $navy-blue-500;
  }
  &.outline-primary:hover {
    background-color: $primary-50;
  }
  &.outline-primary:focus {
    box-shadow: 0 0 0 0.2rem $primary-shadow;
  }
  //===================== Secondary =====================
  &.secondary {
    background-color: $secondary-500;
    color: $white-color;
    border: 0.1rem solid $secondary-500;
  }
  &.secondary:hover {
    background-color: $secondary-600;
  }
  &.secondary:focus {
    box-shadow: 0 0 0 0.2rem $secondary-shadow;
  }
  //===================== Error =====================
  &.error {
    background-color: $error-500;
    color: $white-color;
    border: 0.1rem solid $error-500;
  }
  &.error:hover {
    background-color: $error-600;
  }
  &.error:focus {
    box-shadow: 0 0 0 0.2rem $error-shadow;
  }
  //===================== Outline Error =====================
  &.outline-error {
    background-color: $white-color;
    color: $error-500;
    border: 0.1rem solid $error-500;
  }
  &.outline-error:hover {
    background-color: $error-50;
  }
  &.outline-error:focus {
    box-shadow: 0 0 0 0.2rem $error-shadow;
  }
  //===================== Outline Gray =====================
  &.outline-gray {
    background-color: $white-color;
    color: $gray-500;
    border: 0.1rem solid $gray-500;
  }
  &.outline-gray:hover {
    background-color: $gray-100;
  }
  &.outline-gray:focus {
    box-shadow: 0 0 0 0.2rem $gray-shadow;
  }
  //===================== Link Gray =====================
  &.link-gray {
    background-color: transparent;
    color: $gray-500;
    border: 0.1rem solid $gray-500;
  }
  &.link-gray:hover {
    color: $gray-600;
  }
  &.link-gray:focus {
    box-shadow: 0 0 0 0.2rem $gray-shadow;
  }
  //===================== Link Primary =====================
  &.link-primary {
    background-color: transparent;
    color: $navy-blue-500;
  }
  &.link-primary:hover {
    color: $primary-600;
  }
  &.link-primary:focus {
    box-shadow: 0 0 0 0.2rem $gray-shadow;
  }
}
