@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

// tComp means toggleComponent
.tComp {
    width: 100%;

    &-content {
        display: flex;
        list-style: none;
        align-items: center;
        color: $label-color-dark-gray;
        font-weight: 500;

        input:checked+.toggle-switch {
            background-color: $btn-primary-default;
        }

        input:disabled+.toggle-switch {
            background-color: $bg-second-gray;
        }
    }

    &-switch {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0rem 2.4rem;

        & input {
            height: 0;
            width: 0;
            visibility: hidden;
        }
    }

    &-text {
        font-weight: 400;
        @include text-md;

        &.checked {
            font-weight: 600;
            color: $text-black;
        }
    }

    &-textOnly {
        width: 100%;
        display: flex;
        list-style: none;
        padding: 0rem;
        border: 1px solid $btn-text-gray-default;
        border-radius: .8rem;

        & label {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .8rem 2.6rem;
            color: $btn-text-gray-default;
            font-weight: 400;
            @include text-sm;
            cursor: pointer;

            &.active {
                color: #3E6DBC;
                background: #E7EDF7;
                font-weight: 600;
                border: 1px solid #3E6DBC;
                border-top-left-radius: .8rem;
                border-bottom-left-radius: .8rem;
            }

            &.onActive {
                color: #3E6DBC;
                background: #E7EDF7;
                font-weight: 600;
                border: 1px solid #3E6DBC;
                border-top-right-radius: .8rem;
                border-bottom-right-radius: .8rem;
            }
        }
    }

}