@import '../../../../colorvariable.scss';

.formInputType {
  position: relative;
  margin: 0;
  padding: 0;

  label {
    font-weight: 500;
    font-size: 1.4rem;
    transition: all 0.1s ease-in;
    margin-bottom: 0.8rem;
    color: $btn-text-gray-default;
  }

  & .double-icon {
    color: $color-icon;
    box-sizing: border-box;
    margin-top: -0.2rem;
    padding: 0;
    right: 1.2rem;
    position: absolute;
    transform: translateY(50%);
  }

  &.icon {
    color: $color-icon;
    box-sizing: border-box;
    margin-top: -0.2rem;
    padding: 0;
    position: absolute;
    transform: translateY(50%);

    & > p {
      font-weight: 400;
    }

    &.left {
      left: 1.2rem;
    }

    &.password {
      background-color: #fff;
      right: 1.2rem;
    }
  }

  &.unit {
    color: $color-icon;
    box-sizing: border-box;
    right: -5rem;
    padding: 0;
    font-weight: 400;
    color: $text-black;
    position: absolute;
    transform: translateY(50%);
  }

  &.isError {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: $btn-error-default;
  }
}
