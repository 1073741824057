@import "../../../../colorvariable.scss";

.wrapper-job-listing {
	&__card-job-list {
		border-radius: 8px;
		border: 1px solid var(--Stroke, #ccdaef);
		padding: 2.4rem 0;

		&:hover {
			cursor: pointer;
			box-shadow: 0px 5px 13px 0px rgba(25, 53, 96, 0.12);

			.wrapper-job-listing__card-job-list__title {
				font-size: 2rem;
				color: $secondary-500;
				font-weight: 600;
			}
		}

		& > *:not(&__border-bottom) {
			padding: 0 2.4rem;
		}

		&__title {
			font-size: 2rem;
			color: $label-text-black;
			font-weight: 600;
		}

		&__creator {
			font-size: 1.4rem;
			color: $gray-500;
			margin-bottom: 0;

			&__line {
				width: 1px;
				height: 1.6rem;
				background-color: $border-bottom-dropdown;
				display: inline-block;
				margin: -0.35rem 0.8rem;
			}
		}

		&__stats {
			display: flex;
			// gap: 1.6rem;
			align-items: center;
			margin: 2rem 0;
			&-candidates {
				margin-bottom: 0;
				color: $gray-500;
				font-weight: 400;

				span {
					color: $label-text-black;
					font-size: 1.8rem;
					font-weight: 700;
					margin-right: 0.4rem;
				}
			}

			&-line {
				width: 1px;
				height: 1.6rem;
				background-color: $border-bottom-dropdown;
				display: inline-block;
				margin: 0 0.8rem;
			}
		}

		&__wrapper-tag {
			display: flex;
			gap: 1rem;
			align-items: center;
		}

		&__border-bottom {
			height: 1px;
			margin: 2.4rem 0;
			width: 100%;
			background-color: $gray-300;
		}

		&__button-actions {
			// padding: 2.4rem;
			display: flex;
			gap: 3.2rem;

			&_text {
				font-size: 1.4rem;
				color: $navy-blue-500;
				cursor: pointer;
				font-weight: 600;
				margin-bottom: 0;

				&:hover {
					color: $secondary-500;
				}
			}
		}
	}
}
