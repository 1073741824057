.empty-state-job-ads {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;

	&__title {
		color: var(--Black, #1d2939);
		font-size: 16px;
		font-weight: 600;
		margin-top: 5.2rem;
	}

	&__description {
		font-size: 14px;
		margin-top: 0.8rem;
		color: var(--Black, #1d2939);
	}
}
