.custom-input {
	.inputWithIcon {
		position: relative;

		span {
			position: absolute;
			top: 1.2rem;
			left: 1rem;
			color: #667085;
		}

		input {
			padding-left: 3.5rem !important;
		}
	}
}
