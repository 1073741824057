.parent-company-selector {
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 40px 24px;
	align-items: center;
	min-height: 100vh;

	&__image {
		width: 76px;
		height: 47px;
	}

	&__title {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
	}

	&__lists {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;
		align-items: center;
	}

	&__btn {
		padding: 12px 16px;
		border-radius: 8px;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
}
