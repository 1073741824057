@import "../../../colorvariable.scss";


.cardForm{
    background-color: #fff;
    padding: 24px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #CCDAEF;
    border-radius: 12px;
}