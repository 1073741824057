@import "../../colorvariable.scss";

.user {
	max-height: 100vh;

	&__wrapper {
		.pagination-user-mgt {
			border-bottom: 0;
			margin: 0;
			padding-top: 1rem;
		}
		.linear {
			margin-top: 2.4rem;
			margin-bottom: 3.2rem;
		}
		padding: 3.2rem 3.2rem 0 3.2rem;
		.header {
			h2 {
				font-size: 2.5rem;
				font-weight: 550;
			}

			p {
				color: #667085;
				margin-bottom: 0;
				font-weight: 400;
			}
		}

		table {
			margin-bottom: 0;
		}

		.add-users {
			display: flex;
			gap: 1rem;
			justify-content: space-between;
			align-items: center;

			.search {
				position: relative;

				&__icon {
					position: absolute;
					top: 1.3rem;
					left: 1.2rem;
					font-size: 2rem;
					color: #667085;
				}
				&__input {
					background: #ffffff;
					border: 1px solid #d0d5dd;
					box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
					border-radius: 8px;
					padding: 10px 14px;
					padding-left: 4.3rem;

					&:active,
					&:focus {
						outline: none;
					}
				}
			}
			.btn {
				background-color: $bg-primary;
				color: $white-color;
				font-size: 1.5rem;
				padding: 1rem 2rem;
				border-radius: 8px;
			}
		}
	}

	&__table {
		margin-top: 2.4rem;
		height: calc(100vh - 35.3rem);
		overflow-y: auto;
		border: 1px solid $border-input;
		border-radius: 8px 8px 0 0;

		&::-webkit-scrollbar {
			display: none;
		}

		.profile-image {
			border-radius: 50%;
		}

		.table {
			border-radius: 10px;

			thead,
			tbody tr:nth-child(even) {
				background-color: #f2f4f7;
			}

			thead {
				position: sticky;
				top: 0;
				z-index: 9999;
			}
			thead tr th {
				color: #667085;
				font-weight: 500;
				cursor: default;
				background-color: $gray-100;
			}

			thead tr {
				border-top-left-radius: 50px;
			}

			thead tr th,
			tbody tr td {
				padding: 2rem;
				// border-top: 1px solid black;
				border-bottom: 1px solid $border-input;
				// border-top-left-radius:
			}

			&.table-user-management {
				border-radius: 8px;
				// border: 1px solid $border-input;
			}
		}

		.icon {
			color: #667085;
			margin-left: 0.5rem;
			cursor: pointer;

			&-star {
				color: $yellowColor;
				// cursor: default;
			}

			&-trash-disabled {
				color: #e7edf7;
				cursor: not-allowed;
			}
		}

		.tComp-switch {
			margin: 0;
			flex: 1;
		}
		.toggle-switch {
			width: 35px;
		}

		.tComp-content input:disabled + .toggle-switch {
			background-color: #e7edf7;
			cursor: not-allowed;
		}

		input:disabled + .toggle-switch:after {
			// background: #b4c7e5;
		}

		.custom-navigation.flexBox .pagination {
			margin-left: 1rem;
		}
	}
}
