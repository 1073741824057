.quickmove-btn {
    &-container {
        opacity: 0;
        transform: translateY(-25%);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }
    &-show {
        opacity: 1;
        transform: translateY(0);
    }
}
