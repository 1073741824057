.company_management {
	background-color: #f9fafb;
	// background-color: red;
	min-height: 90vh;
	padding: 3rem 0;
	hr,
	p {
		margin: 0;
		padding: 0;
	}
	&_container {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		display: grid;
		gap: 2rem;
		& > h3 {
			color: #101828;
			font-weight: 500;
			font-size: 30px;
		}
		.wrapper {
			border: 1px solid #ccdaef;
			background-color: white;
			padding: 2rem;
			border-radius: 1rem;
			display: grid;
			gap: 2.5rem;
			& > p {
				color: #1d2939;
				font-weight: 600;
				font-size: 18px;
			}
			& > h4 {
				color: #1d2939;
				font-weight: 600;
				font-size: 20px;
			}

			& > .notif {
				display: grid;
				gap: 1.5rem;
				& > p {
					font-weight: 500;
					font-size: 14px;
					display: flex;
					align-items: center;
					gap: 1rem;
					padding: 1rem 1.5rem;
					border-radius: 0.7rem;
					span {
						padding: 0.5rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				p.warning {
					color: #e58906;
					background-color: #fff5e6;
					span {
						background-color: #fef0c7;
						svg {
							color: #dc6803;
						}
					}
				}
				p.reject {
					color: #d92d20;
					background-color: #fef3f2;
					span {
						background-color: #fee4e2;
					}
				}
			}
			& > .form {
				cursor: pointer;
				& > .label {
					display: flex;
					align-items: center;
					justify-content: space-between;
					span.label {
						color: #1d2939;
						font-weight: 600;
						font-size: 14px;
						&::after {
							content: "*";
							color: red;
							font-size: 1.5rem;
						}
					}
					span.status {
						color: #98a2b3;
						font-weight: 600;
						font-size: 14px;
						&--success {
							color: #12b76a;
						}
					}
					div {
						display: flex;
						align-items: center;
						gap: 1rem;
					}
				}
				& > .form-control {
					border: 2px dashed #ccdaef;
					padding: 2rem 3rem;
					border-radius: 1rem;
					margin-top: 8px;
					background-color: #f9fafb;
					&.status-approved {
						background: var(--success-green-50, #ecfdf3);
					}
					.drag-choose {
						display: flex;
						align-items: center;
						gap: 1rem;
						justify-content: center;
						color: #667085;
						font-weight: 400;
						font-size: 14px;
						span {
							color: #193560;
							font-weight: 600;
							font-size: 14px;
							cursor: pointer;
						}
					}
					.file-allowed {
						// background-color: pink;
						color: #667085;
						font-weight: 600;
						font-size: 14px;
						text-align: center;
						margin-top: 0.8rem;
					}
					.has-file {
						// background-color: pink;
						display: grid;
						gap: 1rem;
						justify-items: center;
						span {
							color: #667085;
							font-weight: 400;
							font-size: 14px;
						}
						p {
							color: #193560;
							font-weight: 600;
							font-size: 14px;
						}
					}
					.has-file.exists {
						padding: 2rem 0;
					}
				}
			}
			& > button {
				background-color: #193560;
				width: fit-content;
				color: white;
				font-weight: 600;
				font-size: 14px;
				padding: 1rem 1.5rem;
				border: none;
				outline: none;
				border-radius: 7px;
				&:disabled {
					background-color: #a3aebf;
					cursor: not-allowed;
				}
			}
			& > .company_profile {
				color: #667085;
				font-weight: 400;
				font-size: 16px;
			}
		}
	}

	& .profile_photo {
		display: grid;
		grid-template-columns: auto auto;
		justify-content: flex-start;
		gap: 5rem;
		div:first-child {
			width: 88px;
			height: 88px;
			background-color: #d0d5dd;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 60%;
				height: 60%;
			}
		}
		div:last-child {
			display: grid;
			align-content: center;
			gap: 1.5rem;
			p {
				display: flex;
				align-items: center;
				gap: 10px;
				font-weight: 600;
				font-size: 16px;
				cursor: pointer;
			}
			p:first-child {
				color: #1d2939;
			}
			p:last-child {
				color: #d0d5dd;
			}
		}
	}
}
.company_management__modal-wrapper {
	max-width: 600px;
	& .modal-body {
		& > div {
			width: 100%;
		}
	}
	& .modal-title-label {
		text-align: left;
	}
	& .profile_photo {
		display: flex;
		gap: 12px;
		display: grid;
		grid-template-columns: 40% 60%;
		justify-content: flex-start;
		gap: 2rem;
		div:first-child {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 22px;
			& > div {
				width: 88px;
				height: 88px;
				background-color: #d0d5dd;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 60%;
					height: 60%;
				}
			}
		}
		div:last-child {
			width: 100%;
			display: grid;
			align-content: center;
			gap: 1.5rem;
			p {
				display: flex;
				align-items: center;
				gap: 10px;
				font-weight: 600;
				font-size: 16px;
			}
			p:first-child {
				color: #1d2939;
			}
			p:last-child {
				color: #d0d5dd;
			}
		}
	}
	.wrapper {
		background-color: white;
		padding-top: 2rem;
		border-radius: 1rem;
		display: grid;
		gap: 2.5rem;
		& input {
			display: none;
		}
	}
	& p {
		cursor: pointer;
	}
}

.success_popup {
	width: 420px;
	p {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	.modal-content {
		border-radius: 1rem;
		padding: 1.5rem 1rem;
		.modal-body {
			display: grid;
			gap: 5rem;
			justify-items: center;
			padding: 1rem;
			.content {
				display: grid;
				gap: 2.5rem;
				justify-items: center;
				p {
					color: #1d2939;
					font-weight: 700;
					font-size: 20px;
				}
				span {
					color: #060d18;
					font-weight: 400;
					font-size: 14px;
				}
			}
			.bottom {
				width: 100%;
				display: flex;
				justify-content: center;
				button {
					width: fit-content;
					background-color: #193560;
					color: white;
					font-weight: 600;
					font-size: 14px;
					border: none;
					outline: none;
					padding: 1.5rem 7rem;
					border-radius: 6px;
				}
			}
		}
	}
}
