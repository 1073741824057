@import '../../../../colorvariable.scss';

.job-ads-listing__header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&-title {
		font-size: 2.4rem;
		font-weight: 600;
		color: $label-text-black;
	}
}
