.notification_overlay {
    // background-color: pink;
    padding: 1rem 1.5rem;
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    &_header {
        display: flex;
        align-items: center;
        & > p {
            // background-color: red;
            color: #1D2939;
            font-weight: 600;
            font-size: 24px;
        }
    }
    &_content {

    }
}