@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

// J-select means Job-selectComponent
.J-select {
	width: 100%;

	&-content {
		width: 100%;
		display: flex;
		flex-direction: column;

		// & label {
		//   font-weight: 500;
		//   color: $btn-text-gray-default;
		//   padding-bottom: 0.8rem;
		//   @include text-sm;
		// }

		& .JSelect,
		& .JInput {
			appearance: none;
			-moz-appearance: none;
			-webkit-appearance: none;

			padding: 1.2rem;
			width: 100%;
			border: 1px solid $border-input;
			border-radius: 0.8rem;
			box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
			transition: all 0.1s ease-in;

			&:focus-visible {
				outline: none;
				border: 1px solid $btn-primary-default;
				box-shadow: 0px 3px 0px $btn-outline-hover, 0px 1px 2px rgba(16, 24, 40, 0.05);
			}

			&::placeholder {
				color: red;
			}
		}

		& .JInput {
			&-boxBelow {
				position: absolute;
				width: 100%;
				height: 20vh;
				margin-top: -0.5rem;
				padding: 1.6rem 1rem;
				background-color: white;
				border: 1px solid $border-input;
				border-bottom-left-radius: 0.8rem;
				border-bottom-right-radius: 0.8rem;
			}
		}
	}

	.JArrow {
		position: absolute;
		pointer-events: none;
		top: 1rem;
		right: 1.6rem;
		width: 2.4rem;
		height: 2.4rem;
	}
}
.J-error {
	border-color: #f04438 !important;
}
