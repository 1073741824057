.drop-area {
	margin-top: 3.2rem;
	border-radius: 8px;
	border: 3px dashed var(--Stroke, #ccdaef);
	background: var(--gray-gray-50, #f9fafb);
	padding: 3.6rem 2.4rem;
	max-width: 550px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.6rem;
	align-self: center;
	&-failed {
		border: 3px dashed var(--error-error-400, #f97066);
		background: var(--error-error-50, #fef3f2);
	}

	.placeholder-image {
		height: 12rem;
		width: 12rem;
	}

	&-success {
		border: 3px dashed var(--success-green-400, #32d583);
		background: var(--success-green-50, #ecfdf3);
	}
	&__text {
		font-size: 14px;
		color: #667085;
		margin-bottom: 0;

		strong {
			font-weight: 500;
			color: #193560;
		}
		&__icon {
			margin-left: 0.8rem;
		}
	}

	.text-file-title {
		font-size: 14px;
		color: #1d2939;
		font-weight: 600;
	}

	.text-replace-file {
		color: #193560;
		font-size: 14px;
		font-weight: 500;
	}
}
